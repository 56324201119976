.uncertainty-chart {
  &-title {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid #000;
  }
}

.wrapper-header-button {
  height: 130px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper-btn-apply {
  height: 300px;
  width: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

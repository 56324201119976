// Brand Colors
$brand-color-1: #021f29;
$brand-color-2: #043748;
$brand-color-3: #075b79;
$brand-color-4: #0a7fa9;
$brand-color-5: #0da4d9;
$brand-color-6: #26bdf2;
$brand-color-7: #56ccf5;
$brand-color-8: #86daf8;
$brand-color-9: #b7e9fb;
$brand-color-10: #e7f8fe;

// Natural Color Palette

// Grey
$gray-1: #141414;
$gray-2: #1d1d1d;
$gray-3: #262626;
$gray-4: #303030;
$gray-5: #434343;
$gray-6: #5a5a5a;
$gray-7: #7d7d7d;
$gray-8: #acacac;
$gray-9: #dbdbdb;
$gray-10: #ffffff;
$gray-11: #333333;

// Red
$red-1: #2a1516;
$red-2: #431815;
$red-3: #581f19;
$red-4: #791a1f;
$red-5: #a61d24;
$red-6: #d32029;
$red-7: #e84749;
$red-8: #f37370;
$red-9: #f89f9a;
$red-10: #f8cdc5;

// Yellow
$yellow-1: #2b2311;
$yellow-2: #433911;
$yellow-3: #575014;
$yellow-4: #7c6e14;
$yellow-5: #aa9514;
$yellow-6: #d8bd14;
$yellow-7: #e8d639;
$yellow-8: #f3ea62;
$yellow-9: #f8f48b;
$yellow-10: #f5fab5;

// Green
$green-1: #192311;
$green-1: #1d3712;
$green-1: #274916;
$green-1: #306317;
$green-1: #3c8618;
$green-1: #49aa19;
$green-1: #6abe39;
$green-1: #8fd460;
$green-1: #b2e58b;
$green-1: #d5f2bb;

// Blue
$blue-1: #111c28;
$blue-2: #182040;
$blue-3: #1c2755;
$blue-4: #203175;
$blue-5: #263ea0;
$blue-6: #2b4acb;
$blue-7: #5273e0;
$blue-8: #7f9ef3;
$blue-9: #a8c1f8;
$blue-10: #d2e0fa;

// Gold
$gold-1: #2b2311;
$gold-2: #443211;
$gold-3: #594214;
$gold-4: #7c5914;
$gold-5: #aa7714;
$gold-6: #d89614;
$gold-7: #e8b339;
$gold-8: #f3cc62;
$gold-9: #f8df8b;
$gold-10: #fae8b6;

// Blue Gradient
$blue-gradient-1-color-1: #223e78;
$blue-gradient-1-color-2: #076587;
$blue-gradient-1-color-3: #0c96c8;

$blue-gradient-2-color-1: #223e78;
$blue-gradient-2-color-2: #076587;
$blue-gradient-2-color-3: #0c96c8;

// Black
$black: #000000;

// White
$white: #fff;

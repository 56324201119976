.plot-box {
  display: none;
  flex-direction: column;
  width: 986px;
  height: 100vh;
  border: 1px solid #ccc;
  z-index: 600;
  position: absolute;
  background: $white;
  left: -1000px;
  top: 0;
  animation: plot 0.7s;
  animation-timing-function: ease-in-out;
  -moz-animation: plot 0.7s; /* Firefox */
  -webkit-animation: plot 0.7s; /* Safari and Chrome */
}

.plot-box-actions {
  display: flex;
  justify-content: flex-end;
}

.plot-box-actions-close {
  border: 0;
  font-size: 28px;
  cursor: pointer;
}

@keyframes plot {
   from {
     left: -100px;
   }
   to {
     left: -7px;
   }
}

.plots--hidden {
  left: -1000px;
  top: 0;
}

.plots--showing {
  display: flex;
  left: -8px;
  top: -8px;
}

.scatter-title-zone {
  display: flex;
  width: 100%;
  height: 10%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  border-bottom: 3px solid #505054;
  margin: 0px 49px 0px;
}

.title-zone {
  color: $white;
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: 600;
}

.scatter-title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 49px;
}

.scatter-title-margin {
  margin-top: 30px;
}

.scatter-title-box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.scatters-plot {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  background: #2f2e36;
  height: 100%;
  overflow: auto;
}

.scatters-title {
  width: 300px;
  color: $white;
  font-size: 26px;
  margin-bottom: 0;
  font-weight: bold;
  border-bottom: 3px solid #7959fc;
}

.scatters-legends {
  margin-top: 1.3rem;
  margin-right: 16rem;
  margin-left: 2rem;
  justify-content: flex-end;
}

.scatters-range {
  color: $white;
  font-size: 22px;
}

.scatter-group {
  width: 860px;
  display: flex;
  flex-flow: row wrap;
}

.wrapper-chart-carbon-normalize {
  width: 680px;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}

.plot-c1-c2-sf {
  width: auto !important;
}

.vertical-plot {
  display: inline-flex;
  margin-right: 0.5rem;
  max-height: 725px;
}

.all-plot {
  display: flex;
  width: 100%;
  overflow: auto;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
  }
}

.d-column {
  flex-direction: column;
}

// Box Components
.drag {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.box-content {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: move;
}

.handle {
  width: 10px;
  height: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 12px;
  cursor: se-resize;
}

.box-resize-square {
  width: 10px;
  height: 10px;
  background-color: #333;
  pointer-events: none;
}

.handle-rotate {
  width: 100%;
  height: 20px;
  padding: 0.4rem 0;
  text-align: center;
  svg {
    width: 23px;
    height: 23px;
  }
}

.transition {
  font-size: 14px;
  text-transform: capitalize;
  color: #333;
  background-color: #f7d8be;
  border: 2px solid  #f9ae70;
  width: 85px;
  height: 127px;
  border-radius: 0px;
  opacity: 0.3;
}

.oil {
  font-size: 14px;
  text-transform: capitalize;
  color: #333;
  background-color: #d1dabc;
  border: 2px solid #718656;
  width: 65px;
  height: 107px;
  border-radius: 0px;
  opacity: 0.3;
}

.gas {
  font-size: 14px;
  text-transform: capitalize;
  color: #333;
  background-color: #f1a7a7;
  border: 2px solid #f15555;
  width: 110px;
  height: 165px;
  border-radius: 0px;
  opacity: 0.3;
}

.btn-gas {
  width: 83px;
  padding: 6px;
  background-color: #f1a7a7;
  border: 2px solid #f15555;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.btn-oil {
  width: 83px;
  padding: 6px;
  background-color: #d1dabc;
  border: 2px solid #718656;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.btn-transition {
  width: 83px;
  padding: 6px;
  background-color: #f7d8be;
  border: 2px solid  #f9ae70;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.rotate-icon {
  width: 38px;
  height: 38px;
  pointer-events: none;
}

.rotate-button {
  background: $white;
  padding: 0.4rem;
  margin-top: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.well-phase__btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.well-phase__form-group {
  justify-content: center;
}

.form-well-phase {
  width: 30%;
  display: flex;
  padding-top: 1%;
  flex-direction: column;

  &__label {
    color: $gray-8;
    text-align: left;
  }

  &__input {
    width: 70%;

    &:hover, &:focus {
      border-color: $brand-color-6;
      box-shadow: none;
    }
  }

  &__button-wrapper{
    margin-top: 1.7rem;
  }

  &__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__remove {
    height: 20px;
  }

  .ant-select-auto-complete.ant-select .ant-input {
    border-color: $gray-5;
    &:hover, &:focus {
      border-color: $brand-color-6;
      box-shadow: none;
    }
  }
}

.phase-title{
  width: 20%;
  display: flex;
  flex-direction: column;

  &__text{
    padding-top: 10px;
  }
}

form .ant-select, form .ant-cascader-picker {
  width: 70%;
}

.ant-select-auto-complete.ant-select .ant-select-selection--single {
  background-color: white;
  color: black;
}

.error-style-well-phases{
  position: absolute;
  margin-top: 56px;
  color: $red-6;
}

.bit-size-notification {
  background-color: $gray-1;
  color: $gray-7;
  box-shadow: 0px 2px 8px $black;

  .ant-notification-notice-message {
    font-weight: 600;
    color: $gray-9;
  }

  .ant-notification-notice-close:hover {
    color: $gray-7;
  }
}

.auto-complete-bit-size {
  background-color: $gray-3;
  box-shadow: 0px 2px 8px $black;

  .ant-select-dropdown-menu-item {
    color: $gray-8;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-selected {
    background-color: $gray-5;
  }
}

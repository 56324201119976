.scatter-fluid-title {
  position: absolute;
  top: 42px;
  right: 31px;
  display: flex;
  width: 200px;
  z-index: 100;
  height: 20px;
  font-size: 12px;
  color: #6b6b6b;
}

.transition-title {
  margin-left: 42px;
}

.gas-title {
  margin-left: 44px;
}

#gradient-chart {
  & g.highcharts-legend {
    transform: translate(309px, 46px) !important;
  }
  &-title {
    top: 10px;
    left: 23px;
    z-index: 600;
    font-size: 14px;
    font-weight: bold;
    left: 282px;
    top: 45px;
  }
}

.gradient-container {
  display: flex;
  width: 100%;
  height: 130px;
  justify-content: flex-end;
  padding-bottom: 10px;
  margin-right: 15px;
  border-bottom: 1px solid #333333;
}

.header-gradient {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 13px;
}

.value-header-pp {
  margin-right: 15px;
  left: 322px;
  bottom: 10px;
  color: #f08c34;
  width: 50px;
  text-align: center;
}

.value-header-rft {
  margin-right: 15px;
  left: 226px;
  bottom: 10px;
  color: #54a652;
  width: 50px;
  text-align: center;
}

.value-header-mw {
  margin-right: 15px;
  left: 384px;
  bottom: 10px;
  color: #989898;
  width: 50px;
  text-align: center;
}

.value-header-ecd {
  margin-right: 15px;
  left: 446px;
  bottom: 10px;
  color: #55a856;
  width: 50px;
  text-align: center;
}

.value-header-fg {
  margin-right: 15px;
  left: 508px;
  bottom: 10px;
  color: #ea4a44;
  width: 50px;
  text-align: center;
}

.value-header-rft-dark {
  margin-right: 15px;
  left: 484px;
  bottom: 10px;
  color: #5c5c5c;
  width: 50px;
  text-align: center;
}

.value-header-obg {
  left: 570px;
  bottom: 10px;
  color: #333333;
  width: 50px;
  text-align: center;
}

.fluid-type-container:hover .toggle-crossplot-config,
.scatter-container-c1:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: appear 0.25s ease-in-out;
  width: 34px;
  height: 34px;
}

.c1-c2-by-c3-container:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}

.wetness-container:hover .toggle-crossplot-config--right,
.c1Byc45-container:hover .toggle-crossplot-config--right {
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appearRight 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.ant-btn-circle.ant-btn-lg, .ant-btn-circle-outline.ant-btn-lg {
  min-width: 35px;
}

%button-base {
  display: none;
  width: 36px;
  height: 34px;
  position: absolute;
  top: 4px;
  background-color: #7959fc;
  border-color: #7959fc;
  z-index: 500;
  &:hover,
  &:active,
  &:focus {
    background-color: #7959fc;
    border-color: #7959fc;
  }
}

.toggle-crossplot-config {
  @extend %button-base;
  left: 4px;
}

.toggle-crossplot-config--right {
  @extend %button-base;
  right: 6px;
}

@keyframes appear {
  0% {
    left: -10px;
  }
  100% {
    left: 6px;
  }
}

@keyframes appearRight {
  0% {
    right: -10px;
  }
  100% {
    right: 14px;
  }
}

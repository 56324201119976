/*------------------------------------
  Typography
------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/*
  ROBOTO
  Light 300
  Regular 400
  Medium 500
  Bold 700
*/
@mixin is-roboto($weight: 400, $style: normal) {
  font-family: 'Roboto', sans-serif;
  font-weight: $weight;
  font-style: $style;
}
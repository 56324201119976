.scatters-legends {
  width: 100%;
  display: flex;
}

.legend {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-top: 4px;
  }
  &__box {
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 0.2rem;
  }
  &--oil {
    background-color: #d1dabc;
  }
  &--transition {
    background-color: #f4c094;
  }
  &--gas {
    background-color: #f1a7a7;
  }
  &__label {
    color: $white;
    margin-right: 1rem;
    height: 20px;
  }
}



.geopress-modal {
  width: 550px;
}

.header-geopress {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 16px;
  border-bottom: 1px solid $gray-6;
}

.close-geopress-modal {
  &:hover {
    cursor: pointer;
  }
}

.name-well-geopression {
  padding-bottom: 16px;
}

.btn-step-geopress {
  margin-left: 8px;
}

.error-geopressure-curves {
  justify-content: center;
  margin-left: -20px;
  margin-bottom: 0.6rem;
  font-size: 14px;
  padding-left: 0;
  width: 100%;
  display: flex;
  color: $red-7;
}

.geopressure {
  &-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    > .home__modal-group {
      margin-bottom: 0;
    }
  }
  &-process-message {
    justify-content: center;
    display: flex;
  }
}

.wrapper-select-density {
  & .ant-select-selection--single {
    color: #5a5a5a;
    background: #262626;
    border-color: #434343;
    & > .ant-select-selection__rendered {
      background-color: #262626;
      color: #7d7d7d;
      border-color: #434343;
    }
  }
}


.real-time-litho {
  &-header {
    margin-left: 10px;
    display: flex;
    width: 95%;
    height: 134px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &-title {
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
  }
  &-scales {
    display: flex;
    width: 100%;
    padding: 0 5px 5px 5px;
    border-bottom: 1px solid #000;
    justify-content: space-between;
  }
  &-scales-numbers {
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }
  &-tooltip {
    width: 100%;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    justify-content: center;
    text-transform: uppercase;
  }
}
.hydraulics .ant-tooltip {
    .ant-tooltip-content {
        cursor: pointer;
        transition: all ease-in-out .35s;
        box-shadow: none;

        .ant-tooltip-arrow::before {
            background-color: #6B6879;
            box-shadow: none;
        }

        .ant-tooltip-inner {
            background-color: #6B6879;
            box-shadow: none;
        }

        &:hover {
            .ant-tooltip-arrow::before {
                background-color: #55B92A;
                box-shadow: none;
            }

            .ant-tooltip-inner {
                background-color: #55B92A;
                box-shadow: none;
            }
        }
    }
}

//------ cutting point

.cutting-pointer {
    background-color: #393939;
    transition: all ease-in-out 0.3s;

    &:hover {

        z-index: 999;
        background-color: #55B92A;
    }
}
.nav {
  @extend %flex;
  @extend %d-column;
  width: 100%;
  font-family: 'Lato';
}

%link-style {
  text-decoration: none;
  font-size: 15px;
  font-family: 'Lato';
  color: $white;
}

.sidebar {
  &__aling{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__nav {
    @extend %flex;
    padding-top: 10px;
    width: 70px;
    height: 100vh;
    justify-content: center;
    background-color: $grayLight;
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    transition: min-width 0.15s;
  }

  &__logo-centralized {
    position: absolute;
    left: 80px;
    top: 36px;
  }

  &__toggle-button {
    @extend %flex;
    @extend %d-column;
    @extend %centralize;
    width: 100%;
    height: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    &--expandend {
      border: 1px solid blue;
      padding-right: 0.5rem;
    }
  }

  &__toggle-bar1,
  &__toggle-bar2,
  &__toggle-bar3 {
    @extend %flex;
    width: 26px;
    margin-bottom: 5px;
    border-radius: 1px;
    background-color: $white;
    height: 2px;
    opacity: 0.8;
    transition: all 0.15s;
  }

  &__content {
    &--openend {
      margin-top: 4rem;
    }
    margin-top: 1rem;
  }

  &__avatar-wrapper {
    margin-top: 1.2rem;
  }

  &__list li {
    display: flex;
    align-items: center;
  }

  &__list-item {
    @extend %inline;
    @extend %centralize;
    width: 100%;
    padding: 0.8rem;
    @extend %link-style;
    & > img{
      width: 22px;
    }
    svg {
      width: 30px;
    }
    &:hover {
      text-decoration: none;
      background-color: $grayLight2;
    }
    &:active, &:visited {
      text-decoration: none;
    }
  }

  &__list-text {
    display: none;
  }

  &__settings-item {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
    @extend %link-style;
    > div {
      width: 25px;
    }
  }
}

.expanded {
  min-width: 230px;
  justify-content: flex-end;
  & .sidebar__toggle-button {
    align-items: flex-end;
    padding-right: 0.7rem;
  }
  & .sidebar__list-text {
    display: flex;
    margin-left: 1rem;
  }
  & .sidebar__toggle-bar2 {
    opacity: 0;
  }
  & .sidebar__toggle-bar1 {
    transform: rotate(45deg);
  }
  & .sidebar__toggle-bar3 {
    transform: rotate(-45deg);
    margin-top: -14px;
  }
  & .sidebar__list-item,
  & .sidebar__settings-item {
    justify-content: flex-start;
    padding-left: 1.2rem;
  }
  & .sidebar__logout-btn {
    outline: 0;
  }
}

@media(max-width: 1340px) {
  .sidebar {
    &__nav {
      width: 55px;
    }
    &__logo {
      & > img {
        width: 55px;
      }
    }
  }
}
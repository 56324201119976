.clipboard__container {
  display: flex;
  font-size: 14px;
}
  
.clipboard__wrapper {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
  
.clipboard__input {
  padding: 5px;
  max-width: 70px;
  height: 31px;
  background-color: #1d1c1c;
  border: none;
  outline: none;
}
  
.clipboard__btn {
  background: #1d1c1c !important;
  border: none;
  cursor: pointer;
}
  
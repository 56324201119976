.charts-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  // height: 100%;
  background-color: $white;
  overflow-y: hidden;
  height: calc(100vh - 208px);
}

.charts-wrapper {
  @media(max-width: 1400px) {
    display: flex;
    flex-direction: column;
    background-color: $white;
    overflow-y: hidden;
    max-height: calc(100vh - 247px);
  }

  max-height: 1070px;
}

.chart {
  &__box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.rop-header-chart {
  display: flex;
  width: 100%;
  height: 132px;
  flex-direction: column;
  align-items: flex-end;
}

.charts-container {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  height: 100%;
  width: 100%;
  padding-right: 10px;
  pointer-events: all;
  overflow-y: hidden;
  overflow-x: scroll;


  // NOTE:
  // This is required to avoid get other event 
  // dispatched by other child element
  // this will update the header values
  pointer-events: all;
}

.charts-container__spinner {
  z-index: 100;
  justify-content: center;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
}

.charts-header {
  display: flex;
  align-items: flex-end;

  &__total-depth,
  &__selected-depth {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding-bottom: 0.25rem;
  }

  &__total-depth {
    width: 108px;
    background-color: $white;
    height: 132px;
    justify-content: flex-end;
  }

  &__selected-depth {
    width: 84px;
    height: 132px;
    justify-content: flex-end;
  }
}

.gas-composition {
  &-c1 {
    color: #86229f;
    border-bottom: 1px solid #86229f;
  }

  &-c2 {
    color: #00adf3;
    border-bottom: 1px solid #00adf3;
  }

  &-c3 {
    color: #cf000d;
    border-bottom: 1px solid #cf000d;
  }

  &-c4 {
    color: #22b96c;
    border-bottom: 1px solid #22b96c;
  }

  &-c5 {
    color: #221d1e;
    border-bottom: 1px solid #221d1e;
  }

  &-header {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 212px;
    z-index: 600;
  }

  &__title {
    text-align: center;
  }

  &__values {
    display: flex;
    justify-content: space-between;
  }
}


.charts-container {
  margin-left: -5px;
  margin-top: 2px;
}

.chromatography-labels {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
}

.chromatography {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;

  &-label-0,
  &-label-1,
  &-label-2,
  &-label-3,
  &-label-4,
  &-label-5,
  &-label-6,
  &-label-7,
  &-label-8,
  &-label-9 {
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
  }

  &-label-1 {
    left: -4px;
  }

  &-label-2 {
    left: 42px;
  }

  &-label-3 {
    left: 112px;
  }

  &-label-4 {
    left: 180px;
  }

  &-label-5 {
    left: 248px;
  }

  &-label-6 {
    position: absolute;
    left: 268px;
  }
}

.gas-composition {
  &-c1 {
    color: #86229f;
    border-bottom: 1px solid #86229f;
  }

  &-c2 {
    color: #00adf3;
    border-bottom: 1px solid #00adf3;
  }

  &-c3 {
    color: #cf000d;
    border-bottom: 1px solid #cf000d;
  }

  &-c4 {
    color: #22b96c;
    border-bottom: 1px solid #22b96c;
  }

  &-c5 {
    color: #221d1e;
    border-bottom: 1px solid #221d1e;
  }

  &-header {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 212px;
    z-index: 600;
  }

  &__title {
    text-align: center;
  }

  &__values {
    display: flex;
    justify-content: space-between;
  }
}

.charts-container {
  margin-left: -5px;
  margin-top: 2px;
}

.gasComposition {
  &-labels {
    width: 100%;
    display: flex;
    position: relative;
    height: 20px;
    border-bottom: 1px solid #333;
  }

  &-label-1,
  &-label-2,
  &-label-3,
  &-label-4,
  &-label-5 {
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: 3px;
  }

  &-label-1 {
    left: 0;
  }

  &-label-2 {
    left: 65px;
  }

  &-label-3 {
    left: 132px;
  }

  &-label-4 {
    left: 194px;
  }

  &-label-5 {
    left: 248px;
  }
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
}

.container-checkbox:hover input~.checkmark {
  background-color: #ccc;
}

.container-checkbox input:checked~.checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked~.checkmark:after {
  display: block;
}

.container-checkbox .checkmark:after {
  left: 5px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Gas Ratio
.title-gr {
  justify-content: center;
  position: relative;
}

.target-gr {
  display: flex;
  width: 300px;
  justify-content: center;
}

.show-checkbox-gr {
  display: none;
  padding-left: 10px;
  margin-right: 25px;
}

.target-gr:hover>.show-checkbox-gr {
  display: flex;
  position: absolute;
  right: 4px;
}

// C1, C2, C3
.chromatography-column {
  $self: &;

  &__target {
    display: flex;
    flex-wrap: nowrap;
    width: 100px;
    justify-content: center;

    &:hover {
      >#{$self}__show-checkbox {
        display: flex;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__show-checkbox {
    display: none;
    padding-left: 0;
    margin-right: 0;
  }
}

//ic4
.title-ic4 {
  margin-left: 0;
}

.target-ic4 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center;
}

.show-checkbox-ic4 {
  display: none;
  padding-left: 10px;
  margin-right: 25px;
}

.target-ic4:hover>.show-checkbox-ic4 {
  display: flex;
  position: absolute;
  right: -11px;
}

//nc4
.title-nc4 {
  margin-left: 0;
}

.target-nc4 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center;
}

.show-checkbox-nc4 {
  display: none;
  padding-left: 10px;
  margin-right: 25px;
}

.target-nc4:hover>.show-checkbox-nc4 {
  display: flex;
  position: absolute;
  right: -11px;
}

//iC5
.title-iC5 {
  margin-left: 0;
}

.target-iC5 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center;
}

.show-checkbox-iC5 {
  display: none;
  padding-left: 10px;
  margin-right: 25px;
}

.target-iC5:hover>.show-checkbox-iC5 {
  display: flex;
  position: absolute;
  right: -11px;
}

//nc5
.title-nc5 {
  margin-left: 0;
}

.target-nc5 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center;
}

.show-checkbox-nc5 {
  display: none;
  padding-left: 10px;
  margin-right: 25px;
}

.target-nc5:hover>.show-checkbox-nc5 {
  display: flex;
  position: absolute;
  right: -11px;
}

//gasCompC1
.title-gasCompC1 {
  margin-left: 10px;
  margin-right: 10px;
}

.target-gasCompC1 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
}

.show-checkbox-gasCompC1 {
  display: none;
  padding-left: 0;
}

.target-gasCompC1:hover>.show-checkbox-gasCompC1 {
  display: flex;
}

//gasCompC2
.title-gasCompC2 {
  margin-left: 10px;
  margin-right: 10px;
}

.target-gasCompC2 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
}

.show-checkbox-gasCompC2 {
  display: none;
  padding-left: 0;
}

.target-gasCompC2:hover>.show-checkbox-gasCompC2 {
  display: flex;
}

//gasCompC3
.title-gasCompC3 {
  margin-left: 10px;
  margin-right: 10px;
}

.target-gasCompC3 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
}

.show-checkbox-gasCompC3 {
  display: none;
  padding-left: 0;
}

.target-gasCompC3:hover>.show-checkbox-gasCompC3 {
  display: flex;
}

//gasCompC4
.title-gasCompC4 {
  margin-left: 10px;
  margin-right: 10px;
}

.target-gasCompC4 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
}

.show-checkbox-gasCompC4 {
  display: none;
  padding-left: 0;
}

.target-gasCompC4:hover>.show-checkbox-gasCompC4 {
  display: flex;
}

//gasCompC5
.title-gasCompC5 {
  margin-left: 10px;
  margin-right: 10px;
}

.target-gasCompC5 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
}

.show-checkbox-gasCompC5 {
  display: none;
  padding-left: 0;
}

.target-gasCompC5:hover>.show-checkbox-gasCompC5 {
  display: flex;
}

//characterRatio
.title-characterRatio {
  min-width: 80px;
  margin-left: 28px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.target-characterRatio {
  display: flex;
  flex-wrap: nowrap;
}

.show-checkbox-characterRatio {
  display: none;
  margin-left: 5px;
}

.target-characterRatio:hover>.show-checkbox-characterRatio {
  display: flex;
}

//balanceRatio
.title-balanceRatio {
  min-width: 110px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.target-balanceRatio {
  display: flex;
  flex-wrap: nowrap;
}

.show-checkbox-balanceRatio {
  display: none;
}

.wob-title:hover>.target-balanceRatio>.show-checkbox-balanceRatio {
  display: block;
}

//wetnessRatio
.title-wetnessRatio {
  min-width: 110px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.target-wetnessRatio {
  display: flex;
  flex-wrap: nowrap;
}

.show-checkbox-wetnessRatio {
  display: none;
}

.rop-title:hover>.target-wetnessRatio>.show-checkbox-wetnessRatio {
  display: block;
}

//SFTitle
.target-SFTitle {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.show-checkbox-SFTitle {
  display: none;
  margin-left: 30px;
}

.target-SFTitle:hover>.show-checkbox-SFTitle {
  display: block;
  margin-left: 30px;
}

.lithology-scores-header {
  $self: &;

  display: flex;
  z-index: 20;
  flex-direction: column;
  font-size: 12px;
  width: 200px;
  background-color: $white;
  position: relative;

  &__title {
    text-align: center;
    display: flex;
    width: 100px;
    top: 15px;
    left: 47px;
    justify-content: center;
    position: relative;
  }

  &__values {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-11;
  }

  &__checkbox {
    display: none;
    position: absolute;
    left: 140px;
    top: 20px;
  }

  &__value-crosshair {
    padding-left: 4px;
  }

  &:hover {
    display: flex;

    >#{$self}__checkbox {
      display: flex;
    }
  }
}
/*------------------------------------
  Login Page
------------------------------------*/
#root {
  width: 100%;
}

.login-page{
  background: $white;
  height: 100vh;

  &__section-form{

  }

  &__section-carousel{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#223e78+0,076587+51,0c96c8+98 */
    // background: #223e78; /* Old browsers */
    // background: -moz-linear-gradient(left,  #223e78 0%, #076587 51%, #0c96c8 98%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(left,  #223e78 0%,#076587 51%,#0c96c8 98%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(left right,  #223e78 0%,#076587 51%,#0c96c8 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#223e78', endColorstr='#0c96c8',GradientType=0 ); /* IE6-9 */
    background: linear-gradient(35.68deg, #223E78 0%, #076587 51.55%, #0C96C8 98.23%);
    height: 100vh;
  }

  // Login header
  &__header {
    text-align: left;
    margin-bottom: 1.875rem;

    &-image{
      text-align: center;
      margin-bottom: 1.5625rem;
    }

    &-image-logo svg {
      width: 16.375rem;
    }

    &-title {
      display: block;
      @include is-roboto(700);
      font-size: 2rem;
      line-height: 3rem;
      letter-spacing: 0.0625rem;
      color: $colorMineShaft;
      margin-bottom: 0;
    }

    &-subtitle {
      display: block;
      @include is-roboto(500);
      font-size: 0.875rem;
      line-height: 1.5rem;
      letter-spacing: 0.0625rem;
      color: $colorRollingStone;
      margin-bottom: 0;
    }

  }
}

// Carousel
.carousel-start {
  &__item {
    position: relative;
    width: 100%;
    height: 100vh;

    &-image {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 90%;
      margin: 0 auto;
    }

    &-title {
      display: block;
      @include is-roboto(700);
      font-size: 2.5rem;
      line-height: 3.75rem;
      letter-spacing: 0.0625rem;
      color: $white;
      margin-top: 4.6875rem;
    }
  }

  // Ant default classes
  .ant-carousel {
    height: 100%;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    height: 100vh;
    line-height: 10rem;
    overflow: hidden;
  }

}


// Login Form
.form-login {
  margin-bottom: 1.875rem;
  padding: 0.25rem;

  &__error {

  }

  .ant-form-item {
    margin-bottom: 0.5rem;
    &-label {
      line-height: 1.5rem;
      text-align: left;
      margin-bottom: 0.625rem;

      label{
        @include is-roboto(700);
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.0625rem;
        color: $colorShuttleGray;

        &:after {
          display: none;
        }
      }
    }

    &-control-wrapper {
      .ant-input {
        height: 3.5rem;
        padding: 0.6875rem;
        @include is-roboto();
        font-size: 0.9375rem;
        color: $colorRollingStone;
        border-color: $colorRollingStone;
      }
      .ant-form-explain {
        margin-top: 0.1875rem;
      }
    }
  }

  &__btn-wrapper{
    margin-top: 0.75rem;
  }
}

.btn-submit{
  background: linear-gradient(50.36deg, #223E78 0%, #076587 51.55%, #0C96C8 98.23%);
  border-color: initial;
  display: block;
  width: 100%;
  height: 3rem;
  color: $white;
  @include is-roboto(500);
  font-size: 1rem;
  letter-spacing: 0.0625rem;

  &:hover, &:active, &:focus{
    background: linear-gradient(50.36deg, #223E78 0%, #076587 51.55%, #0C96C8 98.23%);
    border-color: initial;
    color: $white;
  }
}


// Copyright
.copyright{
  text-align: center;

  &__logo {

    &-image svg {
      width: 9rem;
      height: auto;
    }
  }

  &__info{
    text-align: center;

    &-text{
      @include is-roboto();
      font-size: 0.9375rem;
      color: $colorRollingStone;
      font-size: 0.875rem;
      line-height: 1.5rem;
      display: block;
    }
  }
}

// Responsive Changes
@media (max-width: 767.98px) {
  .login-page {
    padding-top: 2rem;
    height: 100%;
    padding-bottom: 1.2rem;
    overflow-y: auto;
  }
  .copyright {
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {

  // Carousel
  .carousel-start {
    &__item {
      &-image {
        bottom: 2.5rem;
        left: 0;
        right: 0;
        width: 150%;
      }

      &-title {
        display: block;
        font-size: 1.6rem;
        line-height: 2.15rem;
        letter-spacing: 0.0625rem;
      }
    }
  }
}

.scatter-chart {
  z-index: 10 !important;
  width: 400px;
}

.box {
  &-editor-container {
    display: inline-flex;
    width: 400px;
    height: 400px;
  }
  &-editor-base {
    width: 600px;
    z-index: 0;
    display: inline-flex;
    position: relative;
    overflow: hidden;
    background: #fff;
  }
  &-editor-area {
    position: absolute;
    right: 0;
    display: inline-block;
    width: 530px;
    height: 344px;
    z-index: 20;
  }
  &-drag {
    position: absolute;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
    border-radius: 8px;
    padding: 20px;
    width: 120px;
    box-sizing: border-box;
  }
  &-actions {
    width: 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    > .rotate,
    > .resize {
      height: 30px;
    }
    display: none;
  }
}

.target {
  height: 100px;
  margin-top: 100px;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: #fff;
}

.target:hover {
  cursor: pointer;
}


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7959fc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  &--small {
    width: 25px;
    height: 25px;
    border: 4px solid rgba(230, 230, 230, 0.22);
    border-left-color: #ffffff;
  }
}

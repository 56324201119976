.modal-scale__header {
  @extend %between;
  align-items: center;
  height: 57px;
  padding: 20px 40px 13px;
  border-bottom: 1px solid $gray;
  margin-bottom: 26px;
}

.modal-scale__close {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal-scale__form {
  @extend %flex-column;
  justify-content: flex-end;
}

.modal-scale__title {
  margin: 0;
  height: 29px;
} 

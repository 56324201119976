.fullscreen {
  width: 94%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.fullscreen-enabled {
  padding: 24px;
}

.wrapper-full-screen {
  display: none;
  height: 120px;
  width: 40px;
  position: absolute;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
  margin-top: 34px;
  animation: fullscreen-disabled 0.25s ease-in-out;
}

.fullscreen-enabled .wrapper-full-screen {
  animation: fullscreen-enabled 0.25s ease-in-out;
}

@keyframes fullscreen-disabled {
  0% {
    left: 6%;
  }
  100% {
    left: 6.5%;
  }
}

@keyframes fullscreen-enabled {
  0% {
    left: 15px;
  }
  100% {
    left: 25px;
  }
}

.fullscreen:hover .wrapper-full-screen {
  display: flex;
  pointer-events: auto;
}

.wrapper-full-screen__btn {
  display: flex;
  justify-content: center;
  padding: 5px;
  background-color: $gray-3;
  border-radius: 2px;
  margin-top: 100px;
  margin-left: 15px;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: $gray-4;
  }

  &:active {
    background-color: $gray-4;
  }
}

.wrapper-full-screen {
  @media(max-width: 1400px) {
    margin-top: 100px;
  }
}

.wrapper-modal-sensor {
  width: 518px;
  height: 380px;
}

.header-FID-MS {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #43414D;
  padding: 17px 24px;
  font-weight: 600;
}

.icon-close-modal-sensor {
  cursor: pointer;
}

.info-modal-sensor {
  padding: 0px 24px 16px 24px;
  margin-top: 18px;
  color: $gray-9;
}

.info-modal-ms {
  margin-top: 16px;
}

.text-sensor-weight {
  font-weight: 600;
}

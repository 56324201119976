.btn-zoom {
  &__container {
    position: relative;
  }
  padding: 0.5rem;
  background-color: #2f2e36;
  border-radius: 4px;
  color: #fff;
  position: absolute;
  right: 50px;
  cursor: pointer;
  z-index: 600;
}

.zoom {
  &__container {
    z-index: 800;
  }
  &-visible {
    display: flex;
  }
  &-hidden {
    display: none;
  }
}

$dark1: #2F2E36;
$dark2: #43414D;
$blue: #007bff;
$white: #fff;

.popup-info {
  color: $gray-9;
}

.popup-info-fid-ms {
  padding-top: 10px;
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
}

.ant-popover {
  font-size: 16px;
}

.popup-sensor-type .ant-popover-inner {
  width: 280px;
  background-color: $dark1;
}

.ant-popover-title {
  color: $white;
  border-bottom: 1px solid $dark2;
  padding: 5px 12px;
}

.ant-popover-inner-content {
  padding: 12px;
}

.ant-popover-placement-top >
.ant-popover-content >
.ant-popover-arrow {
  background-color: $dark1;
  border-bottom-color: $dark1;
  border-right-color: $dark1;
}

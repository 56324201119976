.lithology {
  width: 136px;
  display: inline-flex;
  height: 40px;
  padding: 0 10px;
  &-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #fff;
  }
  & span {
    display: inline-flex;
    align-items: center;
    font-size: 0.8em;
    height: 100%;
  }
}
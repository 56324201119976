.wrapper-preview {
  height: 780px;
  padding: 5px 0px 0px 16px;
  background-color: $grayLight;
}

.vertical-Thumb {
  background-color: $white;
  width: 5px;
  opacity: 0.5;
  border-radius: 8px;
  cursor: pointer;
}

.lithology-score-chart {
  &-title {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid #000;
  }
}

.litho-score-title {
  position: absolute;
  top: 40px;
}

/*-----------SELECTED  DEPTH ----------*/
#selected-depth {
  display: inline-flex;
  width: 100px;
  height: 132px;
}

#selected-depth .highcharts-yaxis-grid path {
  stroke: #333;
}

.selected-depth__header-with-unit {
  width: 100%;
  display: flex;
  justify-content: center;
}

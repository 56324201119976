/*---------TOTAL DEPTH------------*/
/* Color of center line */
#total-depth .highcharts-yaxis-grid path {
  stroke: #ccc;
}

#total-depth {
  display: inline-flex;
  overflow: visible !important;
}

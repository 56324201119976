.progress-bar {
  width: 140px;
}

.card {
  &__gauge {
    position: relative;
    color: $white;
    &-content {
      position: absolute;
      top: 35px;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
    &-title {
      font-size: 16px;
    }
    &-value {
      font-size: 22px;
      color: $cardValueColor;
    }
    &-unit {
      font-size: 14px;
    }
    position: relative;
    &-container {
      width: 150px !important;
      & > svg {
        width: 150px !important;
        height: 150px !important;
      }
    }
  }
}


.ant-select-dropdown {
    background-color: #211F2B !important;

    .ant-select-dropdown-menu-item {
        background-color: #211F2B;
    }

    .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active {
        background-color: #343049;
    }

}
.reference-depth-dropdown {
  background-color: $gray-3;
  box-shadow: 0px 2px 8px $black;
  
  .ant-select-dropdown-menu-item {
    color: $gray-8;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-selected {
    background-color: $gray-5;
  }
}
.wrapper-vertical-plot {
  width: 95%;
  padding-top: 30px;
}

.wrapper-c1-c2 {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}

.wrapper-scatter-plot-c1c2 {
  margin-right: 10px;
}

.wrapper-scatterplot-c25 {
  margin-right: 10px;
  height: 471px;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  background: #fff;
}

.wrapper-ic4-ic5-c2 {
  display: flex;
  justify-content: center;
  margin-left: 66px;
  padding-top: 30px;
}

.wraper-ic4-nc4 {
  margin-right: 10px;
}

.wraper-ic4-nc4:hover .toggle-crossplot-config{
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}

.wrapper-ic5-nc5 {
  margin-right: 5px;
  margin-left: 9px;
}

.wrapper-ic5-nc5:hover .toggle-crossplot-config{
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}

.wrapper-c2-c3:hover .toggle-crossplot-config{
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}

.wrapper-c2-ic4-c2-by-c3 {
  width: 900px !important; 
  height: 300px; 
  display: flex; 
  flex-direction: row;
}

.wrapper-c2-ic4 {
  margin-right: 10px;
  height: 296px;
}

.wrapper-c2-ic4:hover .toggle-crossplot-config{
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}

.title-vertical-c1byc2 {
  position: absolute;
  bottom: 8px;
  right: 120px;
  z-index: 9999;
  font-size: 12px;
  padding-top: 5px;
  color: #000;
}

.title-vertical-c-25 {
  position: absolute;
  bottom: 8px;
  right: 120px;
  z-index: 9999;
  font-size: 12px;
  padding-top: 5px;
  color: #000;
}

.title-vertical-c-5-oil {
  position: absolute;
  top: 72px;
  left: 90px;
  z-index: 9999;
  font-size: 10px;
  padding-top: 5px;
  color: rgb(106, 124, 82);
  font-weight: bold;
}

.title-vertical-c-5-gas {
  position: absolute;
  top: 72px;
  left: 190px;
  z-index: 9999;
  font-size: 10px;
  padding-top: 5px;
  color: rgb(241, 85, 85);
  font-weight: bold;
}

.title-vertical-c1byc2-oil {
  position: absolute;
  top: 72px;
  left: 90px;
  z-index: 9999;
  font-size: 10px;
  padding-top: 5px;
  color: rgb(113, 134, 86);
  font-weight: bold;
}

.title-vertical-c1byc2-gas {
  position: absolute;
  top: 72px;
  left: 210px;
  z-index: 9999;
  font-size: 10px;
  padding-top: 5px;
  color: rgb(241, 85, 85);
  font-weight: bold;
}

.wrapper-scatter-plot-c1c2:hover .toggle-crossplot-config{
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}

.wrapper-scatterplot-c25:hover .toggle-crossplot-config{
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}

.box-editor-container:hover .toggle-crossplot-config{
  padding-top: 3px;
  padding-right: 0.13px;
  animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}

.wrapper-scatter-plot-c1c2 {
  position: relative;
  z-index: 0;
  height: 471px;
  background: #fff;
  border-radius: 10px;
}


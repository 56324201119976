.wrapper-model {
  &__select { 
    .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
      color: $white;
    }
    & > .ant-select-selection {
      width: 150px;
      color: $white;
      margin-right: 8px;
      background-color: $bgDark3 !important;

      &:hover {
        border-color: $brand-color-6;
      }
    }
    & .ant-select-arrow .ant-select-arrow-icon > svg {
      fill: $white;
    }

    &-dropdown {
      width: 150px !important;
      background-color: $gray-3;
      box-shadow: 0px 2px 8px $black;

      .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
      .ant-select-dropdown-menu-item-selected {
        background-color: $gray-5;
      }
    }

    &-option {
      background-color: $gray-3;
      color: $gray-10;
    
      &:hover, &:active {
        background-color: $gray-5 !important;
      }
    }
  }
}
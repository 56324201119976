.card {
  transition: 0.3s;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  &__container {
    width: 90%;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $darkCard;
    font-family: 'Roboto';
    font-weight: 500;
    border-radius: 6px;
  }

  &__description {
    min-width: 36%;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    flex-direction: column;
    & > p {
      text-align: center;
      line-height: 18px;
    }
  }

  &__value {
    display: inline-flex;
    justify-content: center;
    align-items: baseline;

    width: 100%;
    color: $cardValueColor;
    border-radius: 6px;
    font-size: 24px;
  }

  &--extra-mini {
    display: inline-flex;
    width: 140px;
    height: 50px;
    & .card__value {
      font-size: 18px;
    }
  }

  &--mini {
    display: inline-flex;
    width: 215px;
    height: 62px;
  }

  &--medium {
    display: inline-flex;
    width: 150px;
    height: 115px;
  }

  &--large {
    display: inline-flex;
    width: 320px;
    height: 90px;
  }

  // Bit Depth
  &__bit-depth {
    flex-direction: column;
    background-color: $bgCardBlack;
    align-items: center;
    &-title {
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: $white;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-unit {
      color: #ccc;
      font-size: 1.2rem;
    }
    &-value {
      color: $cardValueColor;
      font-size: 30px;
    }
  }

  &__medium {
    flex-direction: column;
    background-color: $bgCardBlack;
    &-title {
      width: 100%;
      text-align: center;
      font-size: 1.3em;
      color: $white;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-unit {
      color: #ccc;
      font-size: 0.9em;
    }
    &-value {
      width: 100%;
      text-align: center;
      color: $cardValueColor;
      font-size: 30px;
    }
  }
  &__total-gas {
    & > .card__medium-title {
      height: 70px;
    }
  }
  &__lag-time {
    & > .card__medium-title {
      margin-top: 0.5rem;
    }
  }
}

.hole {
  background: linear-gradient(144deg, #ec6631, #d98a2e);
}

.carbon, .wrapper-carbon {
  width: 42%;
  background: linear-gradient(144deg, #24a233, #2cbd96);
  font-size: 19px;
}

.api, .gor {
  background: linear-gradient(144deg, #1f82e9, #58cbfb);
}

.ethene, .propene {
  background: linear-gradient(144deg, #aa58fd, #7959fc);
}

.card-value__text {
  font-size: 16px;
}

.card-value__unit {
  font-size: 12px;
  padding-left: 6px;
}

.gradient-pressure {
  & > .card__description {
    font-size: 10px;
  }
}

.pressure-loss, .fracture-gradient {
  & > .card__description {
    font-size: 12px;
  }
}

.big-card {
  &__container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $darkCard;
    font-family: 'Roboto';
    font-weight: 500;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $darkCard;
    width: 100%;
    height: 65px;
    margin-top: 6px;
    border-right-width: 1px;
    border-right-style: solid;
    border-image:
    linear-gradient(
    to bottom,
      rgba(255, 255, 255, 0.1),
      rgba(0, 0, 0, 0),
    ) 1 100%;
  }
  &__value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: $cardValueColor;
    border-radius: 6px;
    font-size: 16px;
  }
  &__unit {
    font-weight: normal;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8);
  }
  &__text {
    font-weight: bold;
    font-size: 23px;
  }
  &__description {
    font-weight: 500;
    font-size: 16px;
    color: $white;
    align-self: center;
  }
}

.big-card__last-card-right {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.big-card__last-card-left {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.big-card__last-card-right {
  & > .big-card__content {
    border-width: 0;
  }
}

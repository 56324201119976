@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:700,900&display=swap);
/*------------------------------------
  Typography
------------------------------------*/
/*
  ROBOTO
  Light 300
  Regular 400
  Medium 500
  Bold 700
*/
/** 
* Colors Variables
*/
.wrapper-form-bitSize {
  width: "30%"; }

.well-phase__description {
  color: #ccc; }

.well-phase__title {
  color: #ffffff;
  text-align: center;
  justify-content: center;
  margin-bottom: 0;
  letter-spacing: 0.03em;
  font-size: 1.7em; }

.nav, .sidebar__toggle-button {
  flex-direction: column; }

.form-scale__wrapper-input, .form-scale__box-input, .modal-scale__form {
  display: flex;
  flex-direction: column; }

.sidebar__list-item {
  display: inline-flex; }

.nav, .sidebar__nav, .sidebar__toggle-button, .sidebar__toggle-bar1, .sidebar__toggle-bar2, .sidebar__toggle-bar3, .form-scale, .form-scale__wrapper-inputs {
  display: flex; }

.sidebar__toggle-button, .sidebar__list-item {
  justify-content: center;
  align-items: center; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.form-scale__group-buttons, .modal-scale__header {
  display: flex;
  justify-content: space-between; }

.ant-notification-notice {
  background-color: #262626;
  box-shadow: 0px 2px 8px #000000; }

.ant-notification-notice-message {
  color: #dbdbdb; }

.ant-notification-notice-close {
  color: #7d7d7d; }

* {
  margin: 0;
  padding: 0; }
  * ::-webkit-scrollbar {
    width: 16px; }
  * ::-webkit-scrollbar-thumb {
    height: 56px;
    background-color: #333;
    border-radius: 10px;
    border: 4px solid transparent;
    background-clip: content-box;
    opacity: 0.4; }
  * ::-webkit-scrollbar-corner {
    background: transparent; }

body {
  display: flex;
  font-family: sans-serif; }

html,
body {
  font-size: 16px; }

p {
  margin-bottom: 0 !important; }

/* Gas total */
.rop-wrapper,
.chromatography-wrapper,
.lithology {
  position: relative; }

.rop-min-m-title {
  margin-left: 0;
  color: #3fcf3f;
  text-align: center;
  padding-left: 0px;
  font-size: 10px;
  border-bottom: 1px solid #3fcf3f; }

.rop-m-h-title {
  margin-left: 0;
  color: #000;
  text-align: center;
  font-size: 11px;
  padding-left: 0px;
  border-bottom: 1px solid #000; }

.wob-container {
  position: relative; }

.lithology-container {
  position: relative; }

.lithology-color {
  color: #000; }

.right-number {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px; }

.left-number {
  position: absolute;
  left: 0px;
  top: 0;
  font-size: 10px; }

.ligthology-left-number {
  position: absolute;
  left: -8px;
  top: 20px;
  font-size: 10px; }

.ligthology-right-number {
  position: absolute;
  right: 8px;
  top: 20px;
  font-size: 11px; }

/* lithology */
.lithology-wrapper {
  display: inline-flex; }

.lithology-wrapper .lithology-box {
  max-width: 190px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 6px; }

/* centralize all */
#root {
  display: flex; }

/* --------------Select Graphic----------- */
/* root container */
.gas-wrapper {
  display: flex;
  /* background: #ccc; */
  flex-wrap: wrap; }

/* div wrapper */
#gas-depth-container {
  display: inline-flex; }

.gas-depth-box {
  position: relative; }

/* scatter styles */
#resetLast {
  display: flex;
  position: absolute;
  right: -30px;
  top: 30px;
  background: #fff;
  border: 0;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 0.4rem !important;
  border-radius: 4px;
  color: #757575;
  text-align: center;
  transition: all 0.5s ease; }

#resetAll {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  border: 0;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 0.4rem !important;
  border-radius: 4px;
  color: #757575;
  text-align: center;
  transition: all 0.5s ease; }

.highcharts-button {
  display: none !important; }

#resetLast svg {
  margin-right: 2px;
  fill: #333; }

#resetAll svg {
  fill: #333;
  margin-right: 2px; }

.select-graphic {
  display: flex; }

.reset-text {
  margin-left: 4px; }

/* scatter styles */
.scatters-container {
  display: flex;
  flex-direction: column; }

.select-container {
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: center; }

#gas-scatter {
  width: calc(100% - 160px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; }

.scatter-general {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(255, 255, 255, 0.9);
  margin: 0.1rem;
  background-color: #fff; }

.scatter-plot {
  display: flex;
  flex-direction: column;
  border-radius: 10px; }

.rectangle-container {
  width: 220px;
  height: 300px;
  z-index: 20;
  background-color: transparent;
  position: absolute;
  left: 55px;
  top: -16px;
  opacity: 0.5;
  pointer-events: none; }

.rectangle-element {
  opacity: 0.5; }

.reset-minor-grids path.highcharts-minor-grid-line {
  stroke-width: 0; }

.deactivate-legend .highcharts-legend {
  display: none; }

.ratios-title {
  display: flex;
  width: 100%;
  height: 40px;
  position: absolute;
  left: 10px;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  font-family: arial;
  color: #656464; }

/* ------------- Reason Chart --------------*/
#c1ByC2 {
  display: flex; }

.c1-by-c2-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 13px; }

.c1-title-wrapper {
  position: relative;
  height: 51px;
  display: flex;
  margin-left: 14px;
  background-color: yellow; }

.c1-by-c2-title {
  font-size: 12px; }

.c1-container {
  display: flex;
  width: 157px;
  flex-direction: column;
  text-align: center;
  margin-top: -4px; }

.c1-values {
  display: flex;
  justify-content: space-between;
  color: #e94888;
  font-size: 12px; }

.deactivate-labels {
  opacity: 0; }

/* Chromatography */
.chromatography-chart-container {
  position: absolute;
  bottom: 0;
  width: 320px;
  overflow: hidden;
  height: 406px;
  bottom: -2px; }

.chromatography-title-container {
  display: flex;
  width: 310px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-self: flex-start; }

.chromatography-total-gas {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 80%;
  border-bottom: 1px solid #616161;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -4px;
  margin-top: -10px; }

.chromatography-general-title {
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin-top: 3px; }
  .chromatography-general-title > span {
    text-transform: none; }

.chromatography-title-box {
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center; }

.chromatography-column {
  width: 45%;
  display: inline-flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  font-size: 11px; }

.chromatography-column > div {
  margin-top: 4px; }

.c-tg {
  border-bottom: 1px dotted #000; }

.c-c1 {
  color: #8a221b;
  border-bottom: 1px solid #8a221b; }

.c-c2 {
  color: #468a1f;
  border-bottom: 1px solid #468a1f; }

.c-c3 {
  color: #21236a;
  border-bottom: 1px solid #21236a; }

.c-ic4 {
  color: #e65efd;
  border-bottom: 1px solid #e65efd;
  position: relative; }

.c-nc4 {
  color: #a02f5f;
  border-bottom: 1px dashed #a02f5f;
  position: relative; }

.c-ic5 {
  color: #8e52fd;
  border-bottom: 1px solid #8e52fd;
  position: relative; }

.c-nc5 {
  color: #afacfd;
  border-bottom: 1px solid #afacfd;
  position: relative; }

.arrow-line-container {
  position: relative;
  width: 151px;
  z-index: 20;
  font-size: 13px;
  height: 26px; }

.arrow-line-arrow {
  width: 14px;
  height: 36px;
  position: absolute;
  right: 6px;
  top: 1px; }

.arrow-line {
  position: absolute;
  left: -4px;
  width: 160px;
  height: 50px; }

.arrow-line-wrapper {
  position: absolute;
  bottom: 48px;
  right: 10px; }

.c2-c3-arrow-top {
  z-index: 50;
  left: 92px;
  top: 26px; }

.c2-c3-arrow-bottom {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  bottom: 128px;
  right: 40px;
  z-index: 90; }

.c2-iC4-arrow-top {
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
  top: -20px;
  left: 120px;
  z-index: 90; }

.highcharts-tooltip {
  opacity: 0 !important; }

.truncate {
  display: inline-block;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.charts-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  background-color: #fff;
  overflow-y: hidden;
  height: calc(100vh - 208px); }

.charts-wrapper {
  max-height: 1070px; }
  @media (max-width: 1400px) {
    .charts-wrapper {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      overflow-y: hidden;
      max-height: calc(100vh - 247px); } }

.chart__box-container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.rop-header-chart {
  display: flex;
  width: 100%;
  height: 132px;
  flex-direction: column;
  align-items: flex-end; }

.charts-container {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  height: 100%;
  width: 100%;
  padding-right: 10px;
  pointer-events: all;
  overflow-y: hidden;
  overflow-x: scroll;
  pointer-events: all; }

.charts-container__spinner {
  z-index: 100;
  justify-content: center;
  display: flex;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px; }

.charts-header {
  display: flex;
  align-items: flex-end; }
  .charts-header__total-depth, .charts-header__selected-depth {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding-bottom: 0.25rem; }
  .charts-header__total-depth {
    width: 108px;
    background-color: #fff;
    height: 132px;
    justify-content: flex-end; }
  .charts-header__selected-depth {
    width: 84px;
    height: 132px;
    justify-content: flex-end; }

.gas-composition-c1 {
  color: #86229f;
  border-bottom: 1px solid #86229f; }

.gas-composition-c2 {
  color: #00adf3;
  border-bottom: 1px solid #00adf3; }

.gas-composition-c3 {
  color: #cf000d;
  border-bottom: 1px solid #cf000d; }

.gas-composition-c4 {
  color: #22b96c;
  border-bottom: 1px solid #22b96c; }

.gas-composition-c5 {
  color: #221d1e;
  border-bottom: 1px solid #221d1e; }

.gas-composition-header {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 212px;
  z-index: 600; }

.gas-composition__title {
  text-align: center; }

.gas-composition__values {
  display: flex;
  justify-content: space-between; }

.charts-container {
  margin-left: -5px;
  margin-top: 2px; }

.chromatography-labels {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex; }

.chromatography {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex; }
  .chromatography-label-0, .chromatography-label-1, .chromatography-label-2, .chromatography-label-3, .chromatography-label-4, .chromatography-label-5, .chromatography-label-6, .chromatography-label-7, .chromatography-label-8, .chromatography-label-9 {
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    position: absolute; }
  .chromatography-label-1 {
    left: -4px; }
  .chromatography-label-2 {
    left: 42px; }
  .chromatography-label-3 {
    left: 112px; }
  .chromatography-label-4 {
    left: 180px; }
  .chromatography-label-5 {
    left: 248px; }
  .chromatography-label-6 {
    position: absolute;
    left: 268px; }

.gas-composition-c1 {
  color: #86229f;
  border-bottom: 1px solid #86229f; }

.gas-composition-c2 {
  color: #00adf3;
  border-bottom: 1px solid #00adf3; }

.gas-composition-c3 {
  color: #cf000d;
  border-bottom: 1px solid #cf000d; }

.gas-composition-c4 {
  color: #22b96c;
  border-bottom: 1px solid #22b96c; }

.gas-composition-c5 {
  color: #221d1e;
  border-bottom: 1px solid #221d1e; }

.gas-composition-header {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 212px;
  z-index: 600; }

.gas-composition__title {
  text-align: center; }

.gas-composition__values {
  display: flex;
  justify-content: space-between; }

.charts-container {
  margin-left: -5px;
  margin-top: 2px; }

.gasComposition-labels {
  width: 100%;
  display: flex;
  position: relative;
  height: 20px;
  border-bottom: 1px solid #333; }

.gasComposition-label-1, .gasComposition-label-2, .gasComposition-label-3, .gasComposition-label-4, .gasComposition-label-5 {
  display: inline-flex;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 3px; }

.gasComposition-label-1 {
  left: 0; }

.gasComposition-label-2 {
  left: 65px; }

.gasComposition-label-3 {
  left: 132px; }

.gasComposition-label-4 {
  left: 194px; }

.gasComposition-label-5 {
  left: 248px; }

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  user-select: none;
  display: none; }

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee; }

.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc; }

.container-checkbox input:checked ~ .checkmark {
  background-color: #2196F3; }

.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.container-checkbox input:checked ~ .checkmark:after {
  display: block; }

.container-checkbox .checkmark:after {
  left: 5px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.title-gr {
  justify-content: center;
  position: relative; }

.target-gr {
  display: flex;
  width: 300px;
  justify-content: center; }

.show-checkbox-gr {
  display: none;
  padding-left: 10px;
  margin-right: 25px; }

.target-gr:hover > .show-checkbox-gr {
  display: flex;
  position: absolute;
  right: 4px; }

.chromatography-column__target {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center; }
  .chromatography-column__target:hover > .chromatography-column__show-checkbox {
    display: flex; }

.chromatography-column__title {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px; }

.chromatography-column__show-checkbox {
  display: none;
  padding-left: 0;
  margin-right: 0; }

.title-ic4 {
  margin-left: 0; }

.target-ic4 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center; }

.show-checkbox-ic4 {
  display: none;
  padding-left: 10px;
  margin-right: 25px; }

.target-ic4:hover > .show-checkbox-ic4 {
  display: flex;
  position: absolute;
  right: -11px; }

.title-nc4 {
  margin-left: 0; }

.target-nc4 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center; }

.show-checkbox-nc4 {
  display: none;
  padding-left: 10px;
  margin-right: 25px; }

.target-nc4:hover > .show-checkbox-nc4 {
  display: flex;
  position: absolute;
  right: -11px; }

.title-iC5 {
  margin-left: 0; }

.target-iC5 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center; }

.show-checkbox-iC5 {
  display: none;
  padding-left: 10px;
  margin-right: 25px; }

.target-iC5:hover > .show-checkbox-iC5 {
  display: flex;
  position: absolute;
  right: -11px; }

.title-nc5 {
  margin-left: 0; }

.target-nc5 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: center; }

.show-checkbox-nc5 {
  display: none;
  padding-left: 10px;
  margin-right: 25px; }

.target-nc5:hover > .show-checkbox-nc5 {
  display: flex;
  position: absolute;
  right: -11px; }

.title-gasCompC1 {
  margin-left: 10px;
  margin-right: 10px; }

.target-gasCompC1 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px; }

.show-checkbox-gasCompC1 {
  display: none;
  padding-left: 0; }

.target-gasCompC1:hover > .show-checkbox-gasCompC1 {
  display: flex; }

.title-gasCompC2 {
  margin-left: 10px;
  margin-right: 10px; }

.target-gasCompC2 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px; }

.show-checkbox-gasCompC2 {
  display: none;
  padding-left: 0; }

.target-gasCompC2:hover > .show-checkbox-gasCompC2 {
  display: flex; }

.title-gasCompC3 {
  margin-left: 10px;
  margin-right: 10px; }

.target-gasCompC3 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px; }

.show-checkbox-gasCompC3 {
  display: none;
  padding-left: 0; }

.target-gasCompC3:hover > .show-checkbox-gasCompC3 {
  display: flex; }

.title-gasCompC4 {
  margin-left: 10px;
  margin-right: 10px; }

.target-gasCompC4 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px; }

.show-checkbox-gasCompC4 {
  display: none;
  padding-left: 0; }

.target-gasCompC4:hover > .show-checkbox-gasCompC4 {
  display: flex; }

.title-gasCompC5 {
  margin-left: 10px;
  margin-right: 10px; }

.target-gasCompC5 {
  display: flex;
  flex-wrap: nowrap;
  width: 100px; }

.show-checkbox-gasCompC5 {
  display: none;
  padding-left: 0; }

.target-gasCompC5:hover > .show-checkbox-gasCompC5 {
  display: flex; }

.title-characterRatio {
  min-width: 80px;
  margin-left: 28px;
  display: flex;
  flex-direction: column;
  text-align: center; }

.target-characterRatio {
  display: flex;
  flex-wrap: nowrap; }

.show-checkbox-characterRatio {
  display: none;
  margin-left: 5px; }

.target-characterRatio:hover > .show-checkbox-characterRatio {
  display: flex; }

.title-balanceRatio {
  min-width: 110px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  text-align: center; }

.target-balanceRatio {
  display: flex;
  flex-wrap: nowrap; }

.show-checkbox-balanceRatio {
  display: none; }

.wob-title:hover > .target-balanceRatio > .show-checkbox-balanceRatio {
  display: block; }

.title-wetnessRatio {
  min-width: 110px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  text-align: center; }

.target-wetnessRatio {
  display: flex;
  flex-wrap: nowrap; }

.show-checkbox-wetnessRatio {
  display: none; }

.rop-title:hover > .target-wetnessRatio > .show-checkbox-wetnessRatio {
  display: block; }

.target-SFTitle {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap; }

.show-checkbox-SFTitle {
  display: none;
  margin-left: 30px; }

.target-SFTitle:hover > .show-checkbox-SFTitle {
  display: block;
  margin-left: 30px; }

.lithology-scores-header {
  display: flex;
  z-index: 20;
  flex-direction: column;
  font-size: 12px;
  width: 200px;
  background-color: #fff;
  position: relative; }
  .lithology-scores-header__title {
    text-align: center;
    display: flex;
    width: 100px;
    top: 15px;
    left: 47px;
    justify-content: center;
    position: relative; }
  .lithology-scores-header__values {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333333; }
  .lithology-scores-header__checkbox {
    display: none;
    position: absolute;
    left: 140px;
    top: 20px; }
  .lithology-scores-header__value-crosshair {
    padding-left: 4px; }
  .lithology-scores-header:hover {
    display: flex; }
    .lithology-scores-header:hover > .lithology-scores-header__checkbox {
      display: flex; }

/*---------TOTAL DEPTH------------*/
/* Color of center line */
#total-depth .highcharts-yaxis-grid path {
  stroke: #ccc; }

#total-depth {
  display: inline-flex;
  overflow: visible !important; }

.lithology {
  width: 136px;
  display: inline-flex;
  height: 40px;
  padding: 0 10px; }
  .lithology-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #fff; }
  .lithology span {
    display: inline-flex;
    align-items: center;
    font-size: 0.8em;
    height: 100%; }

.container-item {
  display: none; }

.rop-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
  z-index: 30;
  height: 132px; }

.total-gas-header {
  display: flex;
  z-index: 20;
  flex-direction: column;
  font-size: 12px;
  width: 160px;
  background-color: #fff;
  position: relative;
  background: red; }
  .total-gas-header__title {
    text-align: center;
    display: flex;
    width: 95px;
    top: 15px;
    left: 50px;
    justify-content: center;
    position: relative; }
  .total-gas-header__values {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333; }
  .total-gas-header__checkbox {
    display: none;
    position: absolute;
    left: 144px;
    top: 10px; }
  .total-gas-header:hover {
    display: flex; }
    .total-gas-header:hover > .total-gas-header__checkbox {
      display: flex; }

.title-gas {
  margin-left: 40px; }

.isotope-header {
  width: 170px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #338a61;
  height: 132px;
  justify-content: flex-end; }
  .isotope-header__title {
    text-align: center; }
  .isotope-header__values {
    display: flex;
    justify-content: space-between; }

.rop-wrapper {
  width: 198px;
  display: 'inline-flex'; }

.wob-title,
.rop-title,
.character-title {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  border-bottom: 1px solid #ccc; }

.rop-title {
  display: flex; }

.balance-ratio {
  width: 184px;
  display: inline-flex;
  flex-direction: column; }

.character-title {
  color: #26b5e8;
  border-color: #26b5e8; }

.title-rop {
  text-align: center; }

.target-rop {
  display: flex;
  flex-wrap: nowrap;
  width: 300px;
  justify-content: center;
  position: relative; }

.show-checkbox-rop {
  display: none;
  position: absolute;
  left: 102px;
  top: 0px; }

.target-rop:hover > .show-checkbox-rop {
  position: absolute;
  display: flex; }

.plot-box {
  display: none;
  flex-direction: column;
  width: 986px;
  height: 100vh;
  border: 1px solid #ccc;
  z-index: 600;
  position: absolute;
  background: #fff;
  left: -1000px;
  top: 0;
  animation: plot 0.7s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -moz-animation: plot 0.7s;
  /* Firefox */
  -webkit-animation: plot 0.7s;
  /* Safari and Chrome */ }

.plot-box-actions {
  display: flex;
  justify-content: flex-end; }

.plot-box-actions-close {
  border: 0;
  font-size: 28px;
  cursor: pointer; }

@-webkit-keyframes plot {
  from {
    left: -100px; }
  to {
    left: -7px; } }

@keyframes plot {
  from {
    left: -100px; }
  to {
    left: -7px; } }

.plots--hidden {
  left: -1000px;
  top: 0; }

.plots--showing {
  display: flex;
  left: -8px;
  top: -8px; }

.scatter-title-zone {
  display: flex;
  width: 100%;
  height: 10%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  border-bottom: 3px solid #505054;
  margin: 0px 49px 0px; }

.title-zone {
  color: #fff;
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: 600; }

.scatter-title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 49px; }

.scatter-title-margin {
  margin-top: 30px; }

.scatter-title-box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column; }

.scatters-plot {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  background: #2f2e36;
  height: 100%;
  overflow: auto; }

.scatters-title {
  width: 300px;
  color: #fff;
  font-size: 26px;
  margin-bottom: 0;
  font-weight: bold;
  border-bottom: 3px solid #7959fc; }

.scatters-legends {
  margin-top: 1.3rem;
  margin-right: 16rem;
  margin-left: 2rem;
  justify-content: flex-end; }

.scatters-range {
  color: #fff;
  font-size: 22px; }

.scatter-group {
  width: 860px;
  display: flex;
  flex-flow: row wrap; }

.wrapper-chart-carbon-normalize {
  width: 680px;
  display: flex;
  margin-top: 40px;
  justify-content: space-between; }

.plot-c1-c2-sf {
  width: auto !important; }

.vertical-plot {
  display: inline-flex;
  margin-right: 0.5rem;
  max-height: 725px; }

.all-plot {
  display: flex;
  width: 100%;
  overflow: auto;
  justify-content: center; }
  @media (max-width: 1200px) {
    .all-plot {
      flex-direction: column-reverse;
      flex-wrap: wrap;
      align-items: center; } }

.d-column {
  flex-direction: column; }

.drag {
  display: flex;
  flex-direction: column;
  position: absolute; }

.box-content {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: move; }

.handle {
  width: 10px;
  height: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 12px;
  cursor: se-resize; }

.box-resize-square {
  width: 10px;
  height: 10px;
  background-color: #333;
  pointer-events: none; }

.handle-rotate {
  width: 100%;
  height: 20px;
  padding: 0.4rem 0;
  text-align: center; }
  .handle-rotate svg {
    width: 23px;
    height: 23px; }

.transition {
  font-size: 14px;
  text-transform: capitalize;
  color: #333;
  background-color: #f7d8be;
  border: 2px solid  #f9ae70;
  width: 85px;
  height: 127px;
  border-radius: 0px;
  opacity: 0.3; }

.oil {
  font-size: 14px;
  text-transform: capitalize;
  color: #333;
  background-color: #d1dabc;
  border: 2px solid #718656;
  width: 65px;
  height: 107px;
  border-radius: 0px;
  opacity: 0.3; }

.gas {
  font-size: 14px;
  text-transform: capitalize;
  color: #333;
  background-color: #f1a7a7;
  border: 2px solid #f15555;
  width: 110px;
  height: 165px;
  border-radius: 0px;
  opacity: 0.3; }

.btn-gas {
  width: 83px;
  padding: 6px;
  background-color: #f1a7a7;
  border: 2px solid #f15555;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px; }

.btn-oil {
  width: 83px;
  padding: 6px;
  background-color: #d1dabc;
  border: 2px solid #718656;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px; }

.btn-transition {
  width: 83px;
  padding: 6px;
  background-color: #f7d8be;
  border: 2px solid  #f9ae70;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px; }

.rotate-icon {
  width: 38px;
  height: 38px;
  pointer-events: none; }

.rotate-button {
  background: #fff;
  padding: 0.4rem;
  margin-top: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px; }

.card {
  transition: 0.3s;
  border-radius: 6px;
  display: flex;
  flex-direction: column; }
  .card__container {
    width: 90%;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2F2E35;
    font-family: 'Roboto';
    font-weight: 500;
    border-radius: 6px; }
  .card__description {
    min-width: 36%;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-direction: column; }
    .card__description > p {
      text-align: center;
      line-height: 18px; }
  .card__value {
    display: inline-flex;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    color: #24ff24;
    border-radius: 6px;
    font-size: 24px; }
  .card--extra-mini {
    display: inline-flex;
    width: 140px;
    height: 50px; }
    .card--extra-mini .card__value {
      font-size: 18px; }
  .card--mini {
    display: inline-flex;
    width: 215px;
    height: 62px; }
  .card--medium {
    display: inline-flex;
    width: 150px;
    height: 115px; }
  .card--large {
    display: inline-flex;
    width: 320px;
    height: 90px; }
  .card__bit-depth {
    flex-direction: column;
    background-color: #2f2e35;
    align-items: center; }
    .card__bit-depth-title {
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: #fff;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .card__bit-depth-unit {
      color: #ccc;
      font-size: 1.2rem; }
    .card__bit-depth-value {
      color: #24ff24;
      font-size: 30px; }
  .card__medium {
    flex-direction: column;
    background-color: #2f2e35; }
    .card__medium-title {
      width: 100%;
      text-align: center;
      font-size: 1.3em;
      color: #fff;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .card__medium-unit {
      color: #ccc;
      font-size: 0.9em; }
    .card__medium-value {
      width: 100%;
      text-align: center;
      color: #24ff24;
      font-size: 30px; }
  .card__total-gas > .card__medium-title {
    height: 70px; }
  .card__lag-time > .card__medium-title {
    margin-top: 0.5rem; }

.hole {
  background: linear-gradient(144deg, #ec6631, #d98a2e); }

.carbon, .wrapper-carbon {
  width: 42%;
  background: linear-gradient(144deg, #24a233, #2cbd96);
  font-size: 19px; }

.api, .gor {
  background: linear-gradient(144deg, #1f82e9, #58cbfb); }

.ethene, .propene {
  background: linear-gradient(144deg, #aa58fd, #7959fc); }

.card-value__text {
  font-size: 16px; }

.card-value__unit {
  font-size: 12px;
  padding-left: 6px; }

.gradient-pressure > .card__description {
  font-size: 10px; }

.pressure-loss > .card__description, .fracture-gradient > .card__description {
  font-size: 12px; }

.big-card__container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2F2E35;
  font-family: 'Roboto';
  font-weight: 500; }

.big-card__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #2F2E35;
  width: 100%;
  height: 65px;
  margin-top: 6px;
  border-right-width: 1px;
  border-right-style: solid;
  border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0)) 1 100%; }

.big-card__value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #24ff24;
  border-radius: 6px;
  font-size: 16px; }

.big-card__unit {
  font-weight: normal;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8); }

.big-card__text {
  font-weight: bold;
  font-size: 23px; }

.big-card__description {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  align-self: center; }

.big-card__last-card-right {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important; }

.big-card__last-card-left {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important; }

.big-card__last-card-right > .big-card__content {
  border-width: 0; }

.nav {
  width: 100%;
  font-family: 'Lato'; }

.sidebar__list-item, .sidebar__settings-item {
  text-decoration: none;
  font-size: 15px;
  font-family: 'Lato';
  color: #fff; }

.sidebar__aling {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.sidebar__nav {
  padding-top: 10px;
  width: 70px;
  height: 100vh;
  justify-content: center;
  background-color: #2f2e36;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  transition: min-width 0.15s; }

.sidebar__logo-centralized {
  position: absolute;
  left: 80px;
  top: 36px; }

.sidebar__toggle-button {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none; }
  .sidebar__toggle-button--expandend {
    border: 1px solid blue;
    padding-right: 0.5rem; }

.sidebar__toggle-bar1, .sidebar__toggle-bar2, .sidebar__toggle-bar3 {
  width: 26px;
  margin-bottom: 5px;
  border-radius: 1px;
  background-color: #fff;
  height: 2px;
  opacity: 0.8;
  transition: all 0.15s; }

.sidebar__content {
  margin-top: 1rem; }
  .sidebar__content--openend {
    margin-top: 4rem; }

.sidebar__avatar-wrapper {
  margin-top: 1.2rem; }

.sidebar__list li {
  display: flex;
  align-items: center; }

.sidebar__list-item {
  width: 100%;
  padding: 0.8rem; }
  .sidebar__list-item > img {
    width: 22px; }
  .sidebar__list-item svg {
    width: 30px; }
  .sidebar__list-item:hover {
    text-decoration: none;
    background-color: #414047; }
  .sidebar__list-item:active, .sidebar__list-item:visited {
    text-decoration: none; }

.sidebar__list-text {
  display: none; }

.sidebar__settings-item {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center; }
  .sidebar__settings-item > div {
    width: 25px; }

.expanded {
  min-width: 230px;
  justify-content: flex-end; }
  .expanded .sidebar__toggle-button {
    align-items: flex-end;
    padding-right: 0.7rem; }
  .expanded .sidebar__list-text {
    display: flex;
    margin-left: 1rem; }
  .expanded .sidebar__toggle-bar2 {
    opacity: 0; }
  .expanded .sidebar__toggle-bar1 {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .expanded .sidebar__toggle-bar3 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin-top: -14px; }
  .expanded .sidebar__list-item,
  .expanded .sidebar__settings-item {
    justify-content: flex-start;
    padding-left: 1.2rem; }
  .expanded .sidebar__logout-btn {
    outline: 0; }

@media (max-width: 1340px) {
  .sidebar__nav {
    width: 55px; }
  .sidebar__logo > img {
    width: 55px; } }

.gasComposition-label {
  display: inline-flex;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 3px; }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7959fc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite; }
  .spinner--small {
    width: 25px;
    height: 25px;
    border: 4px solid rgba(230, 230, 230, 0.22);
    border-left-color: #ffffff; }

.scatters-legends {
  width: 100%;
  display: flex; }

.legend__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-top: 4px; }

.legend__box {
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 0.2rem; }

.legend--oil {
  background-color: #d1dabc; }

.legend--transition {
  background-color: #f4c094; }

.legend--gas {
  background-color: #f1a7a7; }

.legend__label {
  color: #fff;
  margin-right: 1rem;
  height: 20px; }

.home {
  background-color: #1d1c22;
  overflow: hidden;
  position: relative;
  height: 100vh; }
  .home__modal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .home__modal-form-group {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    align-items: center;
    position: relative; }
    .home__modal-form-group > label {
      color: #7d7d7d;
      width: 104px;
      text-align: left; }
    .home__modal-form-group .label-top-bottom {
      width: 90px; }
    .home__modal-form-group .well-name-error {
      color: #d32029;
      font-size: 15px;
      position: absolute;
      bottom: -20px;
      left: 88px; }
    .home__modal-form-group .well-name-input,
    .home__modal-form-group .ant-input,
    .home__modal-form-group .label-reference-depth,
    .home__modal-form-group .group-reference-curve {
      background-color: #262626;
      border: 1px solid #434343;
      border-radius: 2px;
      color: #acacac !important; }
    .home__modal-form-group .input-version:hover {
      border-color: #26bdf2; }
    .home__modal-form-group .input-version:focus {
      box-shadow: none; }
    .home__modal-form-group .well-name-input,
    .home__modal-form-group .ant-select-selection {
      background: #262626 !important;
      border: 1px solid #434343;
      border-radius: 2px;
      color: #acacac !important; }
      .home__modal-form-group .well-name-input .ant-select-arrow,
      .home__modal-form-group .ant-select-selection .ant-select-arrow {
        color: #acacac; }
      .home__modal-form-group .well-name-input:hover,
      .home__modal-form-group .ant-select-selection:hover {
        border-color: #26bdf2; }
      .home__modal-form-group .well-name-input:focus,
      .home__modal-form-group .ant-select-selection:focus {
        border-color: #26bdf2;
        box-shadow: none; }
    .home__modal-form-group .ant-select-open .ant-select-selection {
      border-color: #26bdf2;
      box-shadow: none; }
    .home__modal-form-group .ant-slider-rail {
      background-color: #434343; }
    .home__modal-form-group .ant-slider-track {
      background-color: #0da4d9; }
    .home__modal-form-group .ant-slider-handle.ant-tooltip-open {
      border-color: #0da4d9;
      background-color: #262626; }
    .home__modal-form-group .ant-slider:hover .ant-slider-rail {
      background-color: #434343; }
  .home__modal .chart-icon {
    width: 40px;
    height: 40px; }
  .home__modal-group {
    display: flex;
    margin-bottom: 0.6rem;
    margin-right: 70px; }
  .home__modal-footer {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end; }
  .home__modal-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .home__content {
    display: flex;
    width: calc(100vw - 40px);
    margin-left: 70px;
    height: 100%;
    flex-direction: column;
    align-items: center; }
  .home__header {
    display: flex;
    width: 94%;
    height: 76px;
    justify-content: space-between; }
    .home__header-select-label {
      margin-top: 2px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .home__header-name {
      display: inline-flex;
      height: 100%;
      align-items: center;
      font-size: 1.9rem;
      color: #fff;
      font-family: 'Poppins'; }
    @media (max-width: 1367px) {
      .home__header-name {
        font-size: 1.5rem; } }
    @media (max-width: 1340px) {
      .home__header-name {
        font-size: 1.5rem; } }
    @media (max-width: 1275px) {
      .home__header-name {
        font-size: 1.3rem; } }
    .home__header-select-well > .ant-select-selection {
      background-color: #2e2e36 !important; }
      .home__header-select-well > .ant-select-selection:hover {
        border-color: #26bdf2; }
    .home__header-select-well .ant-select-arrow .ant-select-arrow-icon > svg {
      fill: #fff; }
    .home__header-select-well-dropdown {
      background-color: #262626;
      box-shadow: 0px 2px 8px #000000; }
      .home__header-select-well-dropdown .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
      .home__header-select-well-dropdown .ant-select-dropdown-menu-item-selected {
        background-color: #434343; }
    .home__header-actions {
      display: inline-flex;
      height: 100%;
      color: #fff;
      align-items: center; }
      .home__header-actions span {
        margin-right: 5px;
        height: 21px; }
    .home__header .btn-upload {
      font-size: 16px;
      border: 1px solid #dbdbdb;
      background: transparent;
      color: #ffffff;
      padding: 0.21rem 0.5rem;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 10px;
      outline: none;
      justify-content: center;
      align-items: center;
      display: flex; }
      .home__header .btn-upload img {
        margin-right: 4px; }
      .home__header .btn-upload:hover {
        border-color: #26bdf2; }
  .home__main {
    width: 94%;
    display: flex;
    height: calc(100vh - 80px); }
  .home__main-content {
    display: flex;
    width: 100%;
    height: calc(100% - 2vh);
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
    overflow-y: scroll;
    color: #8e8d8d; }
  .home__big-cards {
    width: 100%;
    min-height: 106px;
    display: inline-flex;
    justify-content: space-between; }
  .home__cards {
    width: 100%;
    height: 74px;
    display: inline-flex;
    justify-content: space-between;
    margin-top: 1rem; }
  .home__charts {
    background: transparent;
    border-radius: 8px;
    background: #fff;
    margin-top: 14px; }

.options-well {
  background-color: #262626;
  color: #ffffff; }
  .options-well:hover, .options-well:active {
    background-color: #434343 !important; }

.menu-export-file {
  background-color: #262626; }

.menu-item-export-file {
  background-color: #262626 !important;
  box-shadow: 0px 2px 8px #000000;
  color: #ffffff; }
  .menu-item-export-file:hover, .menu-item-export-file:focus {
    color: #ffffff; }

.pop-remove-well .ant-popover-arrow {
  border-top-color: #141414 !important;
  border-left-color: #141414 !important; }

.pop-remove-well .ant-popover-inner-content {
  background-color: #141414;
  box-shadow: 0px 4px 12px #000000; }

.pop-remove-well .ant-popover-message-title {
  font-weight: 700;
  color: #dbdbdb; }

.pop-remove-well .ant-btn-sm {
  background-color: #141414;
  color: #ffffff;
  border-color: #434343;
  border-radius: 2px; }
  .pop-remove-well .ant-btn-sm:hover {
    color: #0da4d9;
    border-color: #0da4d9; }

.pop-remove-well .ant-btn-primary {
  background-color: #26bdf2;
  color: #ffffff;
  border-color: #26bdf2;
  border-radius: 2px; }
  .pop-remove-well .ant-btn-primary:hover {
    color: #ffffff;
    background-color: #0da4d9;
    border-color: #0da4d9; }

@media (max-width: 1400px) {
  .home__cards {
    height: 60px;
    margin-top: 13px;
    margin-bottom: 11px; } }

@media (max-width: 1400px) {
  .home__big-cards {
    height: 99px; } }

.open-geopress-config,
.machine-learning-btn {
  background-color: #303030;
  border: 1px solid #dbdbdb;
  font-size: 16px;
  color: #ffffff;
  padding: 0.21rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; }
  .open-geopress-config:hover,
  .machine-learning-btn:hover {
    color: #26bdf2 !important;
    border-color: #26bdf2 !important;
    background-color: #303030 !important; }
  .open-geopress-config:focus,
  .machine-learning-btn:focus {
    background-color: #303030;
    border: 1px solid #dbdbdb;
    color: #ffffff; }

.btn-remove-well {
  display: flex;
  justify-content: center;
  background-color: transparent !important;
  border: none;
  width: 30px; }

.btn-machine-learning {
  width: 190px;
  height: 32px;
  background-color: #303030;
  border: 1px solid #dbdbdb;
  font-size: 16px;
  color: #ffffff;
  padding: 0.21rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; }
  .btn-machine-learning:hover, .btn-machine-learning:focus {
    color: #26bdf2;
    border-color: #26bdf2;
    background-color: #303030; }

.btn-upload-csv {
  width: 137px;
  height: 32px;
  background-color: #303030;
  border: 1px solid #dbdbdb;
  font-size: 16px;
  color: #ffffff;
  padding: 0.21rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; }
  .btn-upload-csv:hover, .btn-upload-csv:focus {
    color: #26bdf2;
    border-color: #26bdf2;
    background-color: #303030; }

.ant-slider-tooltip .ant-tooltip-inner {
  background-color: #303030;
  border-radius: 2px; }

.ant-tooltip-placement-top .ant-tooltip-arrow::before {
  background-color: #303030; }

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #dbdbdb; }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    height: 2px;
    background-color: #434343; }

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #7d7d7d; }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    height: 2px;
    background-color: #075b79; }

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #7d7d7d; }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    height: 2px;
    background-color: #434343; }

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #0a7fa9;
  border-color: #0a7fa9; }

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: transparent;
  border: 1px solid #0da4d9; }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #0da4d9; }

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent;
  border: 1px solid #7d7d7d; }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #7d7d7d !important; }

.ant-checkbox-wrapper {
  color: #fff; }

.match-curves-label {
  margin-right: 6px;
  width: 184px;
  display: flex;
  align-items: center; }

.match-relative-label {
  color: #7d7d7d;
  margin-right: 16px;
  width: 100px;
  display: flex;
  text-align: end;
  justify-content: flex-end;
  align-items: center; }

.select-dropdown-style {
  background-color: #262626;
  box-shadow: 0px 2px 8px #000000; }
  .select-dropdown-style .ant-select-dropdown-menu-item {
    color: #acacac; }
  .select-dropdown-style .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .select-dropdown-style .ant-select-dropdown-menu-item-selected {
    background-color: #434343; }

.select-dropdown-density {
  background-color: #262626;
  box-shadow: 0px 2px 8px #000000; }
  .select-dropdown-density .ant-select-dropdown-menu-item {
    color: #acacac; }
  .select-dropdown-density .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .select-dropdown-density .ant-select-dropdown-menu-item-selected {
    background-color: #434343; }

.depth .ant-select-open .ant-select-selection,
.flow .ant-select-open .ant-select-selection,
.rop .ant-select-open .ant-select-selection,
.gamma_ray .ant-select-open .ant-select-selection,
.total_gas .ant-select-open .ant-select-selection,
.c1 .ant-select-open .ant-select-selection,
.c2 .ant-select-open .ant-select-selection,
.c3 .ant-select-open .ant-select-selection,
.c4 .ant-select-open .ant-select-selection,
.ic4 .ant-select-open .ant-select-selection,
.c5 .ant-select-open .ant-select-selection,
.ic5 .ant-select-open .ant-select-selection,
.isotope .ant-select-open .ant-select-selection,
.c2h4 .ant-select-open .ant-select-selection,
.c2h6 .ant-select-open .ant-select-selection,
.c2s .ant-select-open .ant-select-selection,
.wob .ant-select-open .ant-select-selection,
.ecd .ant-select-open .ant-select-selection,
.rpm .ant-select-open .ant-select-selection,
.density .ant-select-open .ant-select-selection,
.overburdenGradient .ant-select-open .ant-select-selection,
.vertical_depth .ant-select-open .ant-select-selection,
.mw .ant-select-open .ant-select-selection {
  border-color: #26bdf2;
  box-shadow: none; }

.cy-depth .ant-select-selection,
.cy-flow .ant-select-selection,
.cy-rop .ant-select-selection,
.cy-gamma_ray .ant-select-selection,
.cy-total_gas .ant-select-selection,
.cy-c1 .ant-select-selection,
.cy-c2 .ant-select-selection,
.cy-c3 .ant-select-selection,
.cy-c4 .ant-select-selection,
.cy-nc4 .ant-select-selection,
.cy-nc5 .ant-select-selection,
.cy-azimuth .ant-select-selection,
.cy-torque .ant-select-selection,
.cy-inclination .ant-select-selection,
.cy-ic4 .ant-select-selection,
.cy-c5 .ant-select-selection,
.cy-ic5 .ant-select-selection,
.cy-isotope .ant-select-selection,
.cy-c2h4 .ant-select-selection,
.cy-c2h6 .ant-select-selection,
.cy-c2s .ant-select-selection,
.cy-wob .ant-select-selection,
.cy-ecd .ant-select-selection,
.cy-rpm .ant-select-selection,
.density .ant-select-selection,
.cy-overburdenGradient .ant-select-selection,
.vertical_depth .ant-select-selection,
.mw .ant-select-selection {
  background-color: #262626;
  color: #7d7d7d;
  border-color: #434343;
  border-radius: 2px; }
  .cy-depth .ant-select-selection:hover, .cy-depth .ant-select-selection:focus,
  .cy-flow .ant-select-selection:hover,
  .cy-flow .ant-select-selection:focus,
  .cy-rop .ant-select-selection:hover,
  .cy-rop .ant-select-selection:focus,
  .cy-gamma_ray .ant-select-selection:hover,
  .cy-gamma_ray .ant-select-selection:focus,
  .cy-total_gas .ant-select-selection:hover,
  .cy-total_gas .ant-select-selection:focus,
  .cy-c1 .ant-select-selection:hover,
  .cy-c1 .ant-select-selection:focus,
  .cy-c2 .ant-select-selection:hover,
  .cy-c2 .ant-select-selection:focus,
  .cy-c3 .ant-select-selection:hover,
  .cy-c3 .ant-select-selection:focus,
  .cy-c4 .ant-select-selection:hover,
  .cy-c4 .ant-select-selection:focus,
  .cy-nc4 .ant-select-selection:hover,
  .cy-nc4 .ant-select-selection:focus,
  .cy-nc5 .ant-select-selection:hover,
  .cy-nc5 .ant-select-selection:focus,
  .cy-azimuth .ant-select-selection:hover,
  .cy-azimuth .ant-select-selection:focus,
  .cy-torque .ant-select-selection:hover,
  .cy-torque .ant-select-selection:focus,
  .cy-inclination .ant-select-selection:hover,
  .cy-inclination .ant-select-selection:focus,
  .cy-ic4 .ant-select-selection:hover,
  .cy-ic4 .ant-select-selection:focus,
  .cy-c5 .ant-select-selection:hover,
  .cy-c5 .ant-select-selection:focus,
  .cy-ic5 .ant-select-selection:hover,
  .cy-ic5 .ant-select-selection:focus,
  .cy-isotope .ant-select-selection:hover,
  .cy-isotope .ant-select-selection:focus,
  .cy-c2h4 .ant-select-selection:hover,
  .cy-c2h4 .ant-select-selection:focus,
  .cy-c2h6 .ant-select-selection:hover,
  .cy-c2h6 .ant-select-selection:focus,
  .cy-c2s .ant-select-selection:hover,
  .cy-c2s .ant-select-selection:focus,
  .cy-wob .ant-select-selection:hover,
  .cy-wob .ant-select-selection:focus,
  .cy-ecd .ant-select-selection:hover,
  .cy-ecd .ant-select-selection:focus,
  .cy-rpm .ant-select-selection:hover,
  .cy-rpm .ant-select-selection:focus,
  .density .ant-select-selection:hover,
  .density .ant-select-selection:focus,
  .cy-overburdenGradient .ant-select-selection:hover,
  .cy-overburdenGradient .ant-select-selection:focus,
  .vertical_depth .ant-select-selection:hover,
  .vertical_depth .ant-select-selection:focus,
  .mw .ant-select-selection:hover,
  .mw .ant-select-selection:focus {
    border-color: #26bdf2;
    box-shadow: none; }

.cy-depth .ant-select-arrow,
.cy-flow .ant-select-arrow,
.cy-rop .ant-select-arrow,
.cy-gamma_ray .ant-select-arrow,
.cy-total_gas .ant-select-arrow,
.cy-c1 .ant-select-arrow,
.cy-c2 .ant-select-arrow,
.cy-c3 .ant-select-arrow,
.cy-c4 .ant-select-arrow,
.cy-nc4 .ant-select-arrow,
.cy-nc5 .ant-select-arrow,
.cy-azimuth .ant-select-arrow,
.cy-torque .ant-select-arrow,
.cy-inclination .ant-select-arrow,
.cy-ic4 .ant-select-arrow,
.cy-c5 .ant-select-arrow,
.cy-ic5 .ant-select-arrow,
.cy-isotope .ant-select-arrow,
.cy-c2h4 .ant-select-arrow,
.cy-c2h6 .ant-select-arrow,
.cy-c2s .ant-select-arrow,
.cy-wob .ant-select-arrow,
.cy-ecd .ant-select-arrow,
.cy-rpm .ant-select-arrow,
.density .ant-select-arrow,
.cy-overburdenGradient .ant-select-arrow,
.vertical_depth .ant-select-arrow,
.mw .ant-select-arrow {
  color: #5a5a5a; }

.wrapper-inputs-match-curves {
  margin-bottom: 16px; }

.wrapper-pressure {
  display: flex;
  width: 436px; }
  .wrapper-pressure label {
    display: flex;
    width: 125px;
    text-align: end;
    margin-right: 16px;
    color: #7d7d7d; }
  .wrapper-pressure .pressure__input {
    width: 200px;
    background-color: #262626;
    color: #7d7d7d;
    border-color: #434343;
    border-radius: 2px; }
    .wrapper-pressure .pressure__input:hover, .wrapper-pressure .pressure__input:focus {
      border-color: #26bdf2;
      box-shadow: none; }

.relative-curve {
  margin-top: 1rem; }

.label-curve {
  text-align: left; }

.group-reference-curve .ant-select {
  width: 100%; }

.group-reference-curve .ant-select-selection {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65); }

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none; }

.btn-cancel-conflict-data,
.cy-btn-cancel,
.cy-btn-previous {
  background-color: #262626;
  border: 1px solid #434343;
  border-radius: 2px;
  color: #ffffff; }
  .btn-cancel-conflict-data:hover,
  .cy-btn-cancel:hover,
  .cy-btn-previous:hover {
    background-color: #262626;
    border-color: #0da4d9;
    color: #0da4d9; }

.btn-next-conflict-data,
.cy-btn-next,
.cy-btn-apply,
.btn-add-bit-size {
  background-color: #26bdf2;
  border: 1px solid #26bdf2;
  border-radius: 2px;
  color: #ffffff; }
  .btn-next-conflict-data:hover, .btn-next-conflict-data:focus,
  .cy-btn-next:hover,
  .cy-btn-next:focus,
  .cy-btn-apply:hover,
  .cy-btn-apply:focus,
  .btn-add-bit-size:hover,
  .btn-add-bit-size:focus {
    background-color: #0da4d9;
    border-color: #0da4d9;
    color: #ffffff; }

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  border-radius: 6px; }

.cross-plot-toggle {
  display: flex; }

.steps-content {
  margin-top: 16px;
  border-radius: 6px;
  min-height: 200px;
  justify-content: center;
  display: flex; }

.steps-action {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end; }

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.8); }

.ant-steps .ant-steps-horizontal .ant-steps-label-horizontal {
  width: 592px; }

.ant-tooltip {
  z-index: 900 !important; }

.wrapper-conflicting-data {
  display: flex;
  padding: 0px;
  border-bottom: 1px solid #434343; }

.conflicting-data-title {
  position: relative;
  color: #EDEDED;
  font-size: 20px;
  line-height: 25px;
  padding-top: 10px;
  font-weight: 500;
  width: 100%; }

.conflicting-data__description {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: #EDEDED;
  font-size: 16px;
  line-height: 16px; }

.conflicting-data__las-name {
  color: #0da4d9;
  margin-left: 4px;
  margin-right: 4px; }

.conflicting-data__well-name {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  align-items: center;
  position: relative;
  justify-content: center; }
  .conflicting-data__well-name label {
    color: #fff;
    text-align: left; }
  .conflicting-data__well-name input {
    width: 80%;
    margin-left: 10px;
    border-radius: 2px;
    color: #ffffff;
    background-color: #262626;
    border-color: #434343; }
    .conflicting-data__well-name input:hover {
      border-color: #26bdf2; }
    .conflicting-data__well-name input:focus {
      border-color: #26bdf2;
      box-shadow: none; }
  .conflicting-data__well-name--disable label {
    color: #acacac; }
  .conflicting-data__well-name--disable input[disabled] {
    color: #5a5a5a;
    background-color: #262626;
    border-color: #434343; }
    .conflicting-data__well-name--disable input[disabled]:hover {
      border-color: #434343; }

.form-radio {
  margin-top: 15px; }
  .form-radio__option {
    position: relative;
    display: block;
    padding-right: 16px;
    color: #acacac;
    font-size: 16px;
    line-height: 16px;
    margin-top: 25px; }

.ant-radio-inner {
  background-color: transparent;
  border: 1px solid #434343; }

@media (max-width: 1400px) {
  .home__header {
    height: 60px; } }

@media (max-width: 1420px) {
  .home-header {
    width: 97%; }
  .home__content {
    margin-left: 50px;
    width: calc(100vw - 30px);
    background: #1d1c22; } }

.tabs-create-well {
  color: #ffffff;
  font-size: 16px;
  padding: 0px 10px; }
  .tabs-create-well--import {
    width: 550px;
    height: 100%; }
  .tabs-create-well--preview {
    width: 1200px;
    height: 850px; }

.bit-size__form-group {
  margin-top: 40px !important; }
  .bit-size__form-group .ant-slider {
    width: 85%;
    margin: 0 auto; }

.bit-size__description {
  color: #dbdbdb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.bit-size__title {
  font-size: 16px;
  text-align: center; }

/* Clipboard Bit Size */
.clipboard__container {
  display: flex; }

.clipboard__wrapper {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.clipboard__input {
  padding: 5px;
  max-width: 70px;
  height: 31px;
  background-color: #1d1c1c;
  border: none;
  outline: none; }

.clipboard__btn {
  background: #1d1c1c !important;
  border: none;
  cursor: pointer; }

.wrapper-overburden {
  margin-bottom: 10px; }

.label-overburden {
  color: #26bdf2;
  text-decoration: underline; }
  .label-overburden:hover {
    cursor: pointer; }

.wrapper-options-overburden {
  width: 580px;
  background-color: #262626;
  border-radius: 5px;
  padding: 20px 20px 24px 20px; }

.wrapper-select-density {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px; }

.wrapper-water-depth {
  display: flex;
  width: 386px;
  margin-top: 16px; }
  .wrapper-water-depth label {
    width: 100px;
    color: #7d7d7d;
    margin-right: 16px;
    text-align: end; }
  .wrapper-water-depth .water-depth__input {
    width: 200px;
    background-color: #262626;
    color: #7d7d7d;
    border-color: #434343;
    border-radius: 2px; }
    .wrapper-water-depth .water-depth__input:hover, .wrapper-water-depth .water-depth__input:focus {
      border-color: #26bdf2;
      box-shadow: none; }

.wrapper-default-curve {
  display: flex;
  margin-top: 19px; }

.label-default-curve {
  color: #7d7d7d;
  margin-left: 33px;
  margin-right: 18px; }

.switch-component .ant-switch {
  background-color: #434343;
  width: 50px; }
  .switch-component .ant-switch-checked {
    background-color: #26bdf2; }

.error-curves {
  color: red; }

.wrapper-header-modal {
  width: 100%;
  text-align: center;
  justify-content: center; }

.ant-message-notice-content {
  background-color: #262626;
  color: #dbdbdb;
  box-shadow: 2px 2px 8px #000000; }

.general-main-header {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.home__big-cards {
  background: #2F2E35;
  align-self: center; }

.home__header-select-well {
  width: 70px;
  margin-right: 0px; }

.title-select-well {
  margin-top: 8px; }

@media (max-width: 1367px) {
  .home__header-name {
    font-size: 1.2rem; }
  .wrapper-live-update {
    width: 36px;
    font-size: 12px;
    margin-top: -8px;
    margin-right: 3px; }
  .title-select-well {
    margin-top: 7px; } }

/*------------------------------------
  Login Page
------------------------------------*/
#root {
  width: 100%; }

.login-page {
  background: #fff;
  height: 100vh; }
  .login-page__section-carousel {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#223e78+0,076587+51,0c96c8+98 */
    background: linear-gradient(35.68deg, #223E78 0%, #076587 51.55%, #0C96C8 98.23%);
    height: 100vh; }
  .login-page__header {
    text-align: left;
    margin-bottom: 1.875rem; }
    .login-page__header-image {
      text-align: center;
      margin-bottom: 1.5625rem; }
    .login-page__header-image-logo svg {
      width: 16.375rem; }
    .login-page__header-title {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 2rem;
      line-height: 3rem;
      letter-spacing: 0.0625rem;
      color: #262626;
      margin-bottom: 0; }
    .login-page__header-subtitle {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 1.5rem;
      letter-spacing: 0.0625rem;
      color: #737C80;
      margin-bottom: 0; }

.carousel-start__item {
  position: relative;
  width: 100%;
  height: 100vh; }
  .carousel-start__item-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto; }
  .carousel-start__item-title {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.5rem;
    line-height: 3.75rem;
    letter-spacing: 0.0625rem;
    color: #fff;
    margin-top: 4.6875rem; }

.carousel-start .ant-carousel {
  height: 100%; }

.carousel-start .ant-carousel .slick-slide {
  text-align: center;
  height: 100vh;
  line-height: 10rem;
  overflow: hidden; }

.form-login {
  margin-bottom: 1.875rem;
  padding: 0.25rem; }
  .form-login .ant-form-item {
    margin-bottom: 0.5rem; }
    .form-login .ant-form-item-label {
      line-height: 1.5rem;
      text-align: left;
      margin-bottom: 0.625rem; }
      .form-login .ant-form-item-label label {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.0625rem;
        color: #526066; }
        .form-login .ant-form-item-label label:after {
          display: none; }
    .form-login .ant-form-item-control-wrapper .ant-input {
      height: 3.5rem;
      padding: 0.6875rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 0.9375rem;
      color: #737C80;
      border-color: #737C80; }
    .form-login .ant-form-item-control-wrapper .ant-form-explain {
      margin-top: 0.1875rem; }
  .form-login__btn-wrapper {
    margin-top: 0.75rem; }

.btn-submit {
  background: linear-gradient(50.36deg, #223E78 0%, #076587 51.55%, #0C96C8 98.23%);
  border-color: initial;
  display: block;
  width: 100%;
  height: 3rem;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 0.0625rem; }
  .btn-submit:hover, .btn-submit:active, .btn-submit:focus {
    background: linear-gradient(50.36deg, #223E78 0%, #076587 51.55%, #0C96C8 98.23%);
    border-color: initial;
    color: #fff; }

.copyright {
  text-align: center; }
  .copyright__logo-image svg {
    width: 9rem;
    height: auto; }
  .copyright__info {
    text-align: center; }
    .copyright__info-text {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 0.9375rem;
      color: #737C80;
      font-size: 0.875rem;
      line-height: 1.5rem;
      display: block; }

@media (max-width: 767.98px) {
  .login-page {
    padding-top: 2rem;
    height: 100%;
    padding-bottom: 1.2rem;
    overflow-y: auto; }
  .copyright {
    padding-bottom: 1.5rem; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .carousel-start__item-image {
    bottom: 2.5rem;
    left: 0;
    right: 0;
    width: 150%; }
  .carousel-start__item-title {
    display: block;
    font-size: 1.6rem;
    line-height: 2.15rem;
    letter-spacing: 0.0625rem; } }

.mnemonic-select {
  justify-content: center;
  display: flex;
  align-items: center; }

.fluid-type-container:hover .toggle-crossplot-config,
.scatter-container-c1:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  width: 34px;
  height: 34px; }

.c1-c2-by-c3-container:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px; }

.wetness-container:hover .toggle-crossplot-config--right,
.c1Byc45-container:hover .toggle-crossplot-config--right {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appearRight 0.25s ease-in-out;
          animation: appearRight 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px; }

.ant-btn-circle.ant-btn-lg, .ant-btn-circle-outline.ant-btn-lg {
  min-width: 35px; }

.toggle-crossplot-config, .toggle-crossplot-config--right {
  display: none;
  width: 36px;
  height: 34px;
  position: absolute;
  top: 4px;
  background-color: #7959fc;
  border-color: #7959fc;
  z-index: 500; }
  .toggle-crossplot-config:hover, .toggle-crossplot-config--right:hover, .toggle-crossplot-config:active, .toggle-crossplot-config--right:active, .toggle-crossplot-config:focus, .toggle-crossplot-config--right:focus {
    background-color: #7959fc;
    border-color: #7959fc; }

.toggle-crossplot-config {
  left: 4px; }

.toggle-crossplot-config--right {
  right: 6px; }

@-webkit-keyframes appear {
  0% {
    left: -10px; }
  100% {
    left: 6px; } }

@keyframes appear {
  0% {
    left: -10px; }
  100% {
    left: 6px; } }

@-webkit-keyframes appearRight {
  0% {
    right: -10px; }
  100% {
    right: 14px; } }

@keyframes appearRight {
  0% {
    right: -10px; }
  100% {
    right: 14px; } }

.title-row {
  height: auto;
  justify-items: center;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #F5F5F5;
  margin-bottom: 0; }

.form-scale {
  flex-direction: column;
  justify-content: flex-start;
  min-width: 400px;
  padding: 20px;
  margin-bottom: 1rem; }
  .form-scale span {
    font-size: 14px;
    color: #808080;
    cursor: pointer; }
  .form-scale__subtitle {
    font-size: 1em;
    font-weight: normal; }
  .form-scale__geopressure {
    min-width: 470px; }

.form-scale__subtitle {
  color: #F5F5F5;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px; }

.form-scale__wrapper-inputs {
  justify-items: center;
  justify-content: flex-start;
  margin-top: 28px;
  min-height: 107px; }

.form-scale__box-input {
  margin-right: 38px; }

.form-scale__label {
  margin-bottom: 7px;
  color: #F5F5F5;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto"; }

.form-scale__input {
  width: 120px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  outline: none;
  background-color: transparent;
  padding: 15px 10px;
  color: #F5F5F5;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  opacity: 0.9; }

.form-scale__wrapper-color {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer; }

.form-scale__tooltip-color {
  position: relative;
  width: 0;
  right: 300px; }

.form-scale__close-tooltip {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 70px;
  left: 0; }

.form-scale__picker {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 330px;
  margin: 15px 0;
  border-radius: 4px; }

.form-scale__picker::before {
  border: 10px solid transparent;
  border-bottom-color: #fff;
  content: '';
  right: 5px;
  bottom: 94px;
  position: absolute; }

.form-scale__group-buttons {
  align-items: center; }
  .form-scale__group-buttons button + button {
    margin-left: 12px; }

.form-scale__group-save-buttons {
  display: flex; }

.form-scale__restore-button, .form-scale__cancel-button {
  background: #dc3545;
  border-radius: 5px;
  padding: 6px 9px;
  font-size: 0.9em;
  color: #F5F5F5;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins"; }

.form-scale__ok-button {
  width: 50px; }

.form-scale__save-button {
  width: 89px; }

.form-scale__cancel-button {
  margin-right: 16px; }

.base-button, .form-scale__ok-button, .form-scale__save-button {
  height: 32px;
  background: #007bff;
  padding: 6px 9px;
  font-size: 0.9em;
  border: none;
  border-radius: 5px;
  color: #F5F5F5;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
  cursor: pointer; }

.form-scale__restore-button, .form-scale__cancel-button,
.form-scale__save-button {
  display: block;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer; }

.form-scale__info-modal {
  display: flex;
  margin-bottom: 16px; }

.form-scale__warning-image {
  display: flex;
  align-items: flex-start;
  margin-right: 14px; }

.form-scale__title-confirm {
  font-family: "Poppins";
  font-weight: 600;
  color: #FCFCFC; }

.form-scale__text-confirm {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: #FCFCFC; }

.form-scale__weight {
  font-weight: 600; }

.form-scale__wrapper-btn-confirm {
  display: flex;
  justify-content: flex-end; }

.form-scale-settings__wrapper {
  display: flex;
  flex-direction: column; }

.form-scale__inputs {
  display: flex; }

.row-scale-settings {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px; }

.scale-settings__field-color {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px; }

.wrapper-vertical-plot {
  width: 95%;
  padding-top: 30px; }

.wrapper-c1-c2 {
  display: flex;
  margin-bottom: 20px;
  justify-content: center; }

.wrapper-scatter-plot-c1c2 {
  margin-right: 10px; }

.wrapper-scatterplot-c25 {
  margin-right: 10px;
  height: 471px;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  background: #fff; }

.wrapper-ic4-ic5-c2 {
  display: flex;
  justify-content: center;
  margin-left: 66px;
  padding-top: 30px; }

.wraper-ic4-nc4 {
  margin-right: 10px; }

.wraper-ic4-nc4:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px; }

.wrapper-ic5-nc5 {
  margin-right: 5px;
  margin-left: 9px; }

.wrapper-ic5-nc5:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px; }

.wrapper-c2-c3:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px; }

.wrapper-c2-ic4-c2-by-c3 {
  width: 900px !important;
  height: 300px;
  display: flex;
  flex-direction: row; }

.wrapper-c2-ic4 {
  margin-right: 10px;
  height: 296px; }

.wrapper-c2-ic4:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px; }

.title-vertical-c1byc2 {
  position: absolute;
  bottom: 8px;
  right: 120px;
  z-index: 9999;
  font-size: 12px;
  padding-top: 5px;
  color: #000; }

.title-vertical-c-25 {
  position: absolute;
  bottom: 8px;
  right: 120px;
  z-index: 9999;
  font-size: 12px;
  padding-top: 5px;
  color: #000; }

.title-vertical-c-5-oil {
  position: absolute;
  top: 72px;
  left: 90px;
  z-index: 9999;
  font-size: 10px;
  padding-top: 5px;
  color: #6a7c52;
  font-weight: bold; }

.title-vertical-c-5-gas {
  position: absolute;
  top: 72px;
  left: 190px;
  z-index: 9999;
  font-size: 10px;
  padding-top: 5px;
  color: #f15555;
  font-weight: bold; }

.title-vertical-c1byc2-oil {
  position: absolute;
  top: 72px;
  left: 90px;
  z-index: 9999;
  font-size: 10px;
  padding-top: 5px;
  color: #718656;
  font-weight: bold; }

.title-vertical-c1byc2-gas {
  position: absolute;
  top: 72px;
  left: 210px;
  z-index: 9999;
  font-size: 10px;
  padding-top: 5px;
  color: #f15555;
  font-weight: bold; }

.wrapper-scatter-plot-c1c2:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px; }

.wrapper-scatterplot-c25:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px; }

.box-editor-container:hover .toggle-crossplot-config {
  padding-top: 3px;
  padding-right: 0.13px;
  -webkit-animation: appear 0.25s ease-in-out;
          animation: appear 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px; }

.wrapper-scatter-plot-c1c2 {
  position: relative;
  z-index: 0;
  height: 471px;
  background: #fff;
  border-radius: 10px; }

@media (max-width: 1400px) {
  .charts-wrapper {
    overflow: auto; }
  .card--mini {
    width: 13rem; } }

@media (min-width: 992px) {
  .card--mini {
    width: 10rem; } }

@media (min-width: 1200px) {
  .card--mini {
    width: 14.8rem; } }

.modal-scale__header {
  align-items: center;
  height: 57px;
  padding: 20px 40px 13px;
  border-bottom: 1px solid #616D72;
  margin-bottom: 26px; }

.modal-scale__close {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  cursor: pointer; }

.modal-scale__form {
  justify-content: flex-end; }

.modal-scale__title {
  margin: 0;
  height: 29px; }

.well-phase__btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem; }

.well-phase__form-group {
  justify-content: center; }

.form-well-phase {
  width: 30%;
  display: flex;
  padding-top: 1%;
  flex-direction: column; }
  .form-well-phase__label {
    color: #acacac;
    text-align: left; }
  .form-well-phase__input {
    width: 70%; }
    .form-well-phase__input:hover, .form-well-phase__input:focus {
      border-color: #26bdf2;
      box-shadow: none; }
  .form-well-phase__button-wrapper {
    margin-top: 1.7rem; }
  .form-well-phase__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer; }
  .form-well-phase__remove {
    height: 20px; }
  .form-well-phase .ant-select-auto-complete.ant-select .ant-input {
    border-color: #434343; }
    .form-well-phase .ant-select-auto-complete.ant-select .ant-input:hover, .form-well-phase .ant-select-auto-complete.ant-select .ant-input:focus {
      border-color: #26bdf2;
      box-shadow: none; }

.phase-title {
  width: 20%;
  display: flex;
  flex-direction: column; }
  .phase-title__text {
    padding-top: 10px; }

form .ant-select, form .ant-cascader-picker {
  width: 70%; }

.ant-select-auto-complete.ant-select .ant-select-selection--single {
  background-color: white;
  color: black; }

.error-style-well-phases {
  position: absolute;
  margin-top: 56px;
  color: #d32029; }

.bit-size-notification {
  background-color: #141414;
  color: #7d7d7d;
  box-shadow: 0px 2px 8px #000000; }
  .bit-size-notification .ant-notification-notice-message {
    font-weight: 600;
    color: #dbdbdb; }
  .bit-size-notification .ant-notification-notice-close:hover {
    color: #7d7d7d; }

.auto-complete-bit-size {
  background-color: #262626;
  box-shadow: 0px 2px 8px #000000; }
  .auto-complete-bit-size .ant-select-dropdown-menu-item {
    color: #acacac; }
  .auto-complete-bit-size .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .auto-complete-bit-size .ant-select-dropdown-menu-item-selected {
    background-color: #434343; }

.wrapper-preview {
  height: 780px;
  padding: 5px 0px 0px 16px;
  background-color: #2f2e36; }

.vertical-Thumb {
  background-color: #fff;
  width: 5px;
  opacity: 0.5;
  border-radius: 8px;
  cursor: pointer; }

.wrapper-modal-sensor {
  width: 518px;
  height: 380px; }

.header-FID-MS {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #43414D;
  padding: 17px 24px;
  font-weight: 600; }

.icon-close-modal-sensor {
  cursor: pointer; }

.info-modal-sensor {
  padding: 0px 24px 16px 24px;
  margin-top: 18px;
  color: #dbdbdb; }

.info-modal-ms {
  margin-top: 16px; }

.text-sensor-weight {
  font-weight: 600; }

.fullscreen {
  width: 94%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px); }

.fullscreen-enabled {
  padding: 24px; }

.wrapper-full-screen {
  display: none;
  height: 120px;
  width: 40px;
  position: absolute;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
  margin-top: 34px;
  -webkit-animation: fullscreen-disabled 0.25s ease-in-out;
          animation: fullscreen-disabled 0.25s ease-in-out; }

.fullscreen-enabled .wrapper-full-screen {
  -webkit-animation: fullscreen-enabled 0.25s ease-in-out;
          animation: fullscreen-enabled 0.25s ease-in-out; }

@-webkit-keyframes fullscreen-disabled {
  0% {
    left: 6%; }
  100% {
    left: 6.5%; } }

@keyframes fullscreen-disabled {
  0% {
    left: 6%; }
  100% {
    left: 6.5%; } }

@-webkit-keyframes fullscreen-enabled {
  0% {
    left: 15px; }
  100% {
    left: 25px; } }

@keyframes fullscreen-enabled {
  0% {
    left: 15px; }
  100% {
    left: 25px; } }

.fullscreen:hover .wrapper-full-screen {
  display: flex;
  pointer-events: auto; }

.wrapper-full-screen__btn {
  display: flex;
  justify-content: center;
  padding: 5px;
  background-color: #262626;
  border-radius: 2px;
  margin-top: 100px;
  margin-left: 15px;
  border: none;
  outline: none;
  cursor: pointer; }
  .wrapper-full-screen__btn:hover {
    background-color: #303030; }
  .wrapper-full-screen__btn:active {
    background-color: #303030; }

@media (max-width: 1400px) {
  .wrapper-full-screen {
    margin-top: 100px; } }

.popup-info {
  color: #dbdbdb; }

.popup-info-fid-ms {
  padding-top: 10px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer; }

.ant-popover {
  font-size: 16px; }

.popup-sensor-type .ant-popover-inner {
  width: 280px;
  background-color: #2F2E36; }

.ant-popover-title {
  color: #fff;
  border-bottom: 1px solid #43414D;
  padding: 5px 12px; }

.ant-popover-inner-content {
  padding: 12px; }

.ant-popover-placement-top >
.ant-popover-content >
.ant-popover-arrow {
  background-color: #2F2E36;
  border-bottom-color: #2F2E36;
  border-right-color: #2F2E36; }

.progress-bar {
  width: 140px; }

.card__gauge {
  position: relative;
  color: #fff;
  position: relative; }
  .card__gauge-content {
    position: absolute;
    top: 35px;
    width: 100%;
    text-align: center;
    font-weight: bold; }
  .card__gauge-title {
    font-size: 16px; }
  .card__gauge-value {
    font-size: 22px;
    color: #24ff24; }
  .card__gauge-unit {
    font-size: 14px; }
  .card__gauge-container {
    width: 150px !important; }
    .card__gauge-container > svg {
      width: 150px !important;
      height: 150px !important; }

.total-gas-header {
  display: flex;
  z-index: 20;
  flex-direction: column;
  font-size: 12px;
  width: 160px;
  background-color: #fff;
  position: relative; }
  .total-gas-header__title {
    text-align: center;
    display: flex;
    width: 65px;
    top: 15px;
    left: 50px;
    justify-content: center;
    position: relative; }
  .total-gas-header__values {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333333; }
  .total-gas-header__checkbox {
    display: none;
    position: absolute;
    left: 115px;
    top: 10px; }
  .total-gas-header:hover {
    display: flex; }
    .total-gas-header:hover > .total-gas-header__checkbox {
      display: flex; }

.lithology-header {
  margin-left: 10px;
  display: flex;
  width: 100%;
  height: 134px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end; }

.lithology-title {
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px; }

.lithology-scales {
  display: flex;
  width: 100%;
  padding: 0 5px 5px 5px;
  border-bottom: 2px solid #000;
  justify-content: space-between; }

.lithology-scales-numbers {
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px; }

.lithology-tooltip {
  width: 100%;
  display: flex;
  font-weight: bold;
  font-size: 12px;
  justify-content: center;
  text-transform: uppercase; }

.real-time-litho-header {
  margin-left: 10px;
  display: flex;
  width: 95%;
  height: 134px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end; }

.real-time-litho-title {
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px; }

.real-time-litho-scales {
  display: flex;
  width: 100%;
  padding: 0 5px 5px 5px;
  border-bottom: 1px solid #000;
  justify-content: space-between; }

.real-time-litho-scales-numbers {
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px; }

.real-time-litho-tooltip {
  width: 100%;
  display: flex;
  font-weight: bold;
  font-size: 12px;
  justify-content: center;
  text-transform: uppercase; }

.uncertainty-chart-title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #000; }

.lithology-score-chart-title {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #000; }

.litho-score-title {
  position: absolute;
  top: 40px; }

#gradient-chart g.highcharts-legend {
  -webkit-transform: translate(309px, 46px) !important;
          transform: translate(309px, 46px) !important; }

#gradient-chart-title {
  top: 10px;
  left: 23px;
  z-index: 600;
  font-size: 14px;
  font-weight: bold;
  left: 282px;
  top: 45px; }

.gradient-container {
  display: flex;
  width: 100%;
  height: 130px;
  justify-content: flex-end;
  padding-bottom: 10px;
  margin-right: 15px;
  border-bottom: 1px solid #333333; }

.header-gradient {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 13px; }

.value-header-pp {
  margin-right: 15px;
  left: 322px;
  bottom: 10px;
  color: #f08c34;
  width: 50px;
  text-align: center; }

.value-header-rft {
  margin-right: 15px;
  left: 226px;
  bottom: 10px;
  color: #54a652;
  width: 50px;
  text-align: center; }

.value-header-mw {
  margin-right: 15px;
  left: 384px;
  bottom: 10px;
  color: #989898;
  width: 50px;
  text-align: center; }

.value-header-ecd {
  margin-right: 15px;
  left: 446px;
  bottom: 10px;
  color: #55a856;
  width: 50px;
  text-align: center; }

.value-header-fg {
  margin-right: 15px;
  left: 508px;
  bottom: 10px;
  color: #ea4a44;
  width: 50px;
  text-align: center; }

.value-header-rft-dark {
  margin-right: 15px;
  left: 484px;
  bottom: 10px;
  color: #5c5c5c;
  width: 50px;
  text-align: center; }

.value-header-obg {
  left: 570px;
  bottom: 10px;
  color: #333333;
  width: 50px;
  text-align: center; }

.uncertainty-chart-title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #000; }

.wrapper-header-button {
  height: 130px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.wrapper-btn-apply {
  height: 300px;
  width: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.geopress-modal {
  width: 550px; }

.header-geopress {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 16px;
  border-bottom: 1px solid #5a5a5a; }

.close-geopress-modal:hover {
  cursor: pointer; }

.name-well-geopression {
  padding-bottom: 16px; }

.btn-step-geopress {
  margin-left: 8px; }

.error-geopressure-curves {
  justify-content: center;
  margin-left: -20px;
  margin-bottom: 0.6rem;
  font-size: 14px;
  padding-left: 0;
  width: 100%;
  display: flex;
  color: #e84749; }

.geopressure-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; }
  .geopressure-field > .home__modal-group {
    margin-bottom: 0; }

.geopressure-process-message {
  justify-content: center;
  display: flex; }

.wrapper-select-density .ant-select-selection--single {
  color: #5a5a5a;
  background: #262626;
  border-color: #434343; }
  .wrapper-select-density .ant-select-selection--single > .ant-select-selection__rendered {
    background-color: #262626;
    color: #7d7d7d;
    border-color: #434343; }

.reference-depth-dropdown {
  background-color: #262626;
  box-shadow: 0px 2px 8px #000000; }
  .reference-depth-dropdown .ant-select-dropdown-menu-item {
    color: #acacac; }
  .reference-depth-dropdown .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .reference-depth-dropdown .ant-select-dropdown-menu-item-selected {
    background-color: #434343; }

.spin-dxc {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.5); }

.spinner--dxc {
  border: 8px solid rgba(0, 0, 0, 0.6);
  border-left-color: #7959fc; }

.message-dxc {
  margin-top: 10px;
  color: #ffffff;
  font-size: 20px; }

.wrapper-model__select .ant-select-selection__placeholder, .wrapper-model__select .ant-select-search__field__placeholder {
  color: #fff; }

.wrapper-model__select > .ant-select-selection {
  width: 150px;
  color: #fff;
  margin-right: 8px;
  background-color: #2e2e36 !important; }
  .wrapper-model__select > .ant-select-selection:hover {
    border-color: #26bdf2; }

.wrapper-model__select .ant-select-arrow .ant-select-arrow-icon > svg {
  fill: #fff; }

.wrapper-model__select-dropdown {
  width: 150px !important;
  background-color: #262626;
  box-shadow: 0px 2px 8px #000000; }
  .wrapper-model__select-dropdown .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .wrapper-model__select-dropdown .ant-select-dropdown-menu-item-selected {
    background-color: #434343; }

.wrapper-model__select-option {
  background-color: #262626;
  color: #ffffff; }
  .wrapper-model__select-option:hover, .wrapper-model__select-option:active {
    background-color: #434343 !important; }

/*-----------SELECTED  DEPTH ----------*/
#selected-depth {
  display: inline-flex;
  width: 100px;
  height: 132px; }

#selected-depth .highcharts-yaxis-grid path {
  stroke: #333; }

.selected-depth__header-with-unit {
  width: 100%;
  display: flex;
  justify-content: center; }

.total-gas-container {
  margin-left: 0.5rem !important; }

.total-gas-labels {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex; }

.total-gas {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex; }
  .total-gas-label-0, .total-gas-label-1, .total-gas-label-2, .total-gas-label-3, .total-gas-label-4, .total-gas-label-5, .total-gas-label-6, .total-gas-label-7, .total-gas-label-8,
  .total-gas-label-9 .total-gas-label-10 .total-gas-label-11 .total-gas-label-12 {
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    position: absolute; }

.btn-close-modal {
  background-color: transparent;
  border: none;
  margin-left: 60px;
  outline: none;
  position: absolute;
  right: 16px;
  top: 10px;
  cursor: pointer; }

.clipboard__container {
  display: flex;
  font-size: 14px; }

.clipboard__wrapper {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.clipboard__input {
  padding: 5px;
  max-width: 70px;
  height: 31px;
  background-color: #1d1c1c;
  border: none;
  outline: none; }

.clipboard__btn {
  background: #1d1c1c !important;
  border: none;
  cursor: pointer; }

.table-select-inWell .ant-table-thead > tr > th {
  background: #1D1C1C; }

.table-select-inWell .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  color: #e6f7ff; }

.ant-table-tbody > tr.ant-table-row-selected td {
  background: #313030; }
  .ant-table-tbody > tr.ant-table-row-selected td:hover {
    background: #313030; }

.table-select-inWell .ant-table-thead > tr:hover.ant-table-row-selected > td,
.table-select-inWell .ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #1D1C1C; }

.table-select-inWell .ant-table-row .ant-table-row-level-0 {
  color: #e6f7ff; }

.table-select-inWell .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #333; }

.table-select-inWell .ant-table-tbody > tr > td {
  border: none; }

.table-select-inWell .ant-table-column-title {
  color: #737C80; }

.table-select-inWell .ant-table-tbody {
  color: #e6f7ff; }

.table-select-inWell .ant-table-row .ant-table-row-level-0 a {
  color: #e6f7ff; }

.table-select-inWell .ant-table-tbody > tr > td a {
  color: #e6f7ff; }

.selectModal-inWell-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #e6f7ff; }

.selectModal-inWell-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #e6f7ff; }

.selectModal-inWell-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #e6f7ff; }

/*********************
    Experiment
*********************/
.experiment-container .ant-tabs-nav-wrap .ant-tabs-tab {
  color: #fff !important; }

.experiment-container .ant-tabs-tabpane {
  display: flex !important;
  justify-content: center !important; }

.experiments .ant-collapse > .ant-collapse-item,
.experiments .ant-collapse-icon-position-right {
  border-bottom: none !important;
  border: 0; }

.experiments .ant-collapse-item {
  color: #fff;
  margin-bottom: 14px; }

.experiments .ant-collapse-header {
  color: #fff !important;
  text-transform: uppercase; }

.experiments .ant-collapse-content .ant-collapse-content-active {
  background: #1d1c1c;
  color: #fff; }

.modal-machine-learning .spinner--small {
  border: 4px solid #ccc;
  border-left-color: #1E1E1E !important; }

/*********************
       Models
*********************/
.container-card-model {
  display: flex;
  justify-content: space-around; }

.models .ant-collapse > .ant-collapse-item,
.models .ant-collapse-icon-position-right {
  border-bottom: none !important;
  border: 0; }

.models .ant-collapse-item {
  background: #1E1E1E !important;
  color: #fff;
  margin-bottom: 14px; }

.models .ant-collapse-header {
  color: #fff !important;
  text-transform: uppercase; }

.models .ant-collapse-content .ant-collapse-content-active {
  background: #1d1c1c;
  color: #fff; }

.models .ant-collapse-content-box {
  background: #202526; }

.modal-models .spinner--small {
  border: 4px solid #ccc;
  border-left-color: #1d1c1c !important; }

.apply-model-button {
  position: absolute;
  right: 12px;
  top: 7px;
  color: #24FF4D;
  border: 1px solid #24FF4D;
  background-color: rgba(24, 144, 255, 0) !important; }
  .apply-model-button:hover {
    border-color: 1px solid #24FF4D; }

.wrapper-card-model {
  padding: 24px;
  width: 360px;
  background: #2F2E35; }

.wrapper-card-settings {
  width: 360px;
  min-height: 400px;
  background: #2F2E35;
  padding: 24px; }

.btn-continue {
  width: 230px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px; }

.box-model-text {
  margin-bottom: 10px; }

.detail-model-title {
  font-size: 16px;
  color: #fff;
  font-weight: 500; }

.detail-model-description {
  font-size: 14px;
  color: #999999;
  padding-bottom: 10px; }

.title-model-lithology {
  width: 240px;
  color: #fff;
  margin-left: 20px; }

.title-model-settings {
  color: #fff;
  margin-left: 140px; }

.select-settings .ant-select-selection--single {
  width: 220px;
  background: #1e1e1e;
  color: #fff; }

.ant-select-dropdown-menu-item,
.ant-select-dropdown-menu-item-selected {
  background: #1e1e1e;
  color: #fff; }

.select-settings {
  background-color: #1e1e1e; }
  .select-settings .ant-select-dropdown-menu-item {
    color: #fff; }
  .select-settings .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .select-settings .ant-select-dropdown-menu-item-selected {
    background: #1e1e1e; }

.experiments-table .ant-table-tbody .experiments-table .ant-table-tbody,
.experiments-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  color: #ffffff !important;
  background: #1d1c1c !important; }

.experiments-table .ant-table-thead > tr:first-child > th {
  min-width: 100px !important; }

.select-settings .ant-select-selection {
  background-color: #1e1e1e;
  color: #fff;
  border-color: #1e1e1e;
  border-radius: 2px; }
  .select-settings .ant-select-selection:hover, .select-settings .ant-select-selection:focus {
    box-shadow: none; }

.select-dropdown-settings-models {
  background-color: #1e1e1e;
  box-shadow: 0px 2px 8px #1e1e1e; }
  .select-dropdown-settings-models .ant-select-dropdown-menu-item {
    color: #fff; }
  .select-dropdown-settings-models .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .select-dropdown-settings-models .ant-select-dropdown-menu-item-selected {
    background-color: #1e1e1e; }

.select-settings .ant-select-arrow .ant-select-arrow-icon > svg {
  fill: #fff; }

.wrapper-message-results {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.model-result-msg-success {
  color: #fff;
  font-style: 18px;
  font-weight: bold; }

.model-result-msg-applied {
  color: #fff;
  font-style: 14px; }

.model-result-msg-drift {
  color: #fff;
  font-style: 14px;
  margin-top: 10px; }

.text-model-msg-success {
  display: flex;
  flex-direction: column;
  align-items: center; }

.btn-see-results {
  width: 240px;
  padding: 14px;
  border: solid 2px #40C300;
  background: #1e1e1e;
  color: #40C300;
  font-size: 12;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center; }

.btn-see-results:hover {
  width: 240px;
  padding: 14px;
  border: solid 2px #40C300;
  background: #1e1e1e;
  color: #40C300;
  font-size: 12;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center; }

.btn-zoom {
  padding: 0.5rem;
  background-color: #2f2e36;
  border-radius: 4px;
  color: #fff;
  position: absolute;
  right: 50px;
  cursor: pointer;
  z-index: 600; }
  .btn-zoom__container {
    position: relative; }

.zoom__container {
  z-index: 800; }

.zoom-visible {
  display: flex; }

.zoom-hidden {
  display: none; }

.overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 800; }
  .overlay-wrapper .overlay-loading-wrapper {
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.overlay .title {
  font-family: sans-serif;
  font-size: 16px;
  color: #fff; }

.overlay-show {
  display: block; }

.overlay-hide {
  display: none; }

.overlay-load {
  width: 100px;
  height: 50px; }

.ant-popover-inner {
  background-color: #D2042D; }

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  background-color: #D2042D;
  border-bottom-color: #D2042D;
  border-right-color: #D2042D; }

.ant-select-dropdown {
  background-color: #211F2B !important; }
  .ant-select-dropdown .ant-select-dropdown-menu-item {
    background-color: #211F2B; }
  .ant-select-dropdown .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active {
    background-color: #343049; }

.hydraulics .ant-tooltip .ant-tooltip-content {
  cursor: pointer;
  transition: all ease-in-out .35s;
  box-shadow: none; }
  .hydraulics .ant-tooltip .ant-tooltip-content .ant-tooltip-arrow::before {
    background-color: #6B6879;
    box-shadow: none; }
  .hydraulics .ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
    background-color: #6B6879;
    box-shadow: none; }
  .hydraulics .ant-tooltip .ant-tooltip-content:hover .ant-tooltip-arrow::before {
    background-color: #55B92A;
    box-shadow: none; }
  .hydraulics .ant-tooltip .ant-tooltip-content:hover .ant-tooltip-inner {
    background-color: #55B92A;
    box-shadow: none; }

.cutting-pointer {
  background-color: #393939;
  transition: all ease-in-out 0.3s; }
  .cutting-pointer:hover {
    z-index: 999;
    background-color: #55B92A; }

.scatter-chart {
  z-index: 10 !important;
  width: 400px; }

.box-editor-container {
  display: inline-flex;
  width: 400px;
  height: 400px; }

.box-editor-base {
  width: 600px;
  z-index: 0;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  background: #fff; }

.box-editor-area {
  position: absolute;
  right: 0;
  display: inline-block;
  width: 530px;
  height: 344px;
  z-index: 20; }

.box-drag {
  position: absolute;
  color: white;
  font-size: 20px;
  font-family: sans-serif;
  border-radius: 8px;
  padding: 20px;
  width: 120px;
  box-sizing: border-box; }

.box-actions {
  width: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  display: none; }
  .box-actions > .rotate,
  .box-actions > .resize {
    height: 30px; }

.target {
  height: 100px;
  margin-top: 100px;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: #fff; }

.target:hover {
  cursor: pointer; }

.scatter-fluid-title {
  position: absolute;
  top: 42px;
  right: 31px;
  display: flex;
  width: 200px;
  z-index: 100;
  height: 20px;
  font-size: 12px;
  color: #6b6b6b; }

.transition-title {
  margin-left: 42px; }

.gas-title {
  margin-left: 44px; }

.scatters-ethene {
  background: #2f2e36;
  height: 100%;
  overflow: auto; }

.scatter-header-ethene {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 45px 0px 61px 66px; }

.scatter-info-ethene {
  display: flex;
  justify-content: flex-start;
  flex-direction: column; }

.scatter-title-ethene {
  width: 300px;
  color: #fff;
  font-size: 26px;
  margin-bottom: 0;
  font-weight: bold;
  border-bottom: 3px solid #7959fc; }

.scatters-ethene-range {
  color: #fff;
  font-size: 22px; }

.ethene-container {
  display: flex;
  justify-content: center; }

.wrapper-button-ethene {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px; }

.toggle-crossplot-ethene {
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  top: 15px;
  right: 4px;
  background-color: #45444f;
  border-color: #45444f; }
  .toggle-crossplot-ethene:hover, .toggle-crossplot-ethene:active, .toggle-crossplot-ethene:focus {
    background-color: #45444f;
    border-color: #45444f;
    color: #fff; }

.wrapper-form-ethene {
  padding: 0px 24px 0px 24px; }

.wrapper-fields-ethene {
  display: flex;
  align-items: center;
  padding-top: 24px; }

.labels-ethene {
  width: 50px; }

.wrapper-error-ethene {
  margin-left: 45px; }

.wrapper-ethene-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0px 24px; }

.ethene-btn-cancel {
  margin-right: 8px; }
  .ethene-btn-cancel .ant-btn:hover, .ethene-btn-cancel .ant-btn:active, .ethene-btn-cancel .ant-btn.active {
    background-color: #fff; }

.ethene-btn-apply {
  margin-left: 8px; }

.settings-ethene-title {
  padding: 16px 24px 16px 24px;
  font-family: 'Poppins';
  color: #000000;
  font-size: 16px;
  border-bottom: solid 1px #E8E8E8; }


.logo-geowellex {
  width: 4%;
  margin: 30px 0 0 30px; }

.container-forgot {
  height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }
  .container-forgot .form-forgot {
    width: 60%;
    height: 42%; }
  .container-forgot .btn__forgot {
    background-image: linear-gradient(to right, #058bb4, #0498c2, #03a6d0, #02b3de, #01c1ec);
    margin-top: 1rem;
    width: 214px;
    padding: 15px 15px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    justify-content: center; }

.container-header-forgot {
  width: 50%;
  height: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }

.title-forgot {
  color: white;
  font-size: 32px;
  font-weight: bold;
  margin: 0; }

.subtitle-forgot {
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400; }

.input-forgot {
  width: 460px; }

hr {
  width: 120px;
  border: 1px dashed white;
  opacity: .5; }

.error-text {
  color: white; }

.container-sucess-menssage {
  width: 59%;
  height: 5vh;
  background-color: #23c138;
  display: flex; }
  .container-sucess-menssage p {
    margin-top: 24px; }

.alert {
  font-size: 18px; }

.msg-error-forgot {
  color: red;
  font-Size: 14px;
  text-Align: center; }

.error-style {
  color: #ff4141;
  font-Size: 14px;
  text-Align: start;
  width: 60%; }

.msg-reset-password {
  font-Size: 16px; }

.msg-back-sing-in {
  font-Size: 15px;
  color: white;
  margin-Top: 5%; }


* {
  margin: 0;
  padding: 0;

  ::-webkit-scrollbar {
    width: 16px;

  }

  ::-webkit-scrollbar-thumb {
    height: 56px;
    background-color: #333;
    border-radius: 10px;
    border: 4px solid transparent;
    background-clip: content-box;

    opacity: 0.4;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@import url('https://fonts.googleapis.com/css?family=Lato:700,900&display=swap');

body {
  display: flex;
  font-family: sans-serif;
}

html,
body {
  font-size: 16px;
}

p {
  margin-bottom: 0 !important;
}

/* Gas total */
.rop-wrapper,
.chromatography-wrapper,
.lithology {
  position: relative;
}

.rop-min-m-title {
  margin-left: 0;
  color: #3fcf3f;
  text-align: center;
  padding-left: 0px;
  font-size: 10px;
  border-bottom: 1px solid #3fcf3f;
}

.rop-m-h-title {
  margin-left: 0;
  color: #000;
  text-align: center;
  font-size: 11px;
  padding-left: 0px;
  border-bottom: 1px solid #000;
}

.wob-container {
  position: relative;
}

.lithology-container {
  position: relative;
}

.lithology-color {
  color: #000;
}

.right-number {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
}

.left-number {
  position: absolute;
  left: 0px;
  top: 0;
  font-size: 10px;
}

.ligthology-left-number {
  position: absolute;
  left: -8px;
  top: 20px;
  font-size: 10px;
}

.ligthology-right-number {
  position: absolute;
  right: 8px;
  top: 20px;
  font-size: 11px;
}

/* lithology */
.lithology-wrapper {
  display: inline-flex;
}

.lithology-wrapper .lithology-box {
  max-width: 190px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 6px;
}

/* centralize all */
#root {
  display: flex;
}

/* --------------Select Graphic----------- */

/* root container */
.gas-wrapper {
  display: flex;
  /* background: #ccc; */
  flex-wrap: wrap;
}

/* div wrapper */
#gas-depth-container {
  display: inline-flex;
}

.gas-depth-box {
  position: relative;
}

/* scatter styles */
#resetLast {
  display: flex;
  position: absolute;
  right: -30px;
  top: 30px;
  background: #fff;
  border: 0;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 0.4rem !important;
  border-radius: 4px;
  color: #757575;
  text-align: center;
  transition: all 0.5s ease;
}

#resetAll {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  border: 0;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 0.4rem !important;
  border-radius: 4px;
  color: #757575;
  text-align: center;
  transition: all 0.5s ease;
}

.highcharts-button {
  display: none !important;
}

#resetLast svg {
  margin-right: 2px;
  fill: #333;
}

#resetAll svg {
  fill: #333;
  margin-right: 2px;
}

.select-graphic {
  display: flex;
}

.reset-text {
  margin-left: 4px;
}

/* scatter styles */
.scatters-container {
  display: flex;
  flex-direction: column;
}

.select-container {
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: center;
}

#gas-scatter {
  width: calc(100% - 160px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.scatter-general {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(255, 255, 255, 0.9);
  margin: 0.1rem;
  background-color: #fff;
}

.scatter-plot {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.rectangle-container {
  width: 220px;
  height: 300px;
  z-index: 20;
  background-color: transparent;
  position: absolute;
  left: 55px;
  top: -16px;
  opacity: 0.5;
  pointer-events: none;
}

.rectangle-element {
  opacity: 0.5;
}

.reset-minor-grids path.highcharts-minor-grid-line {
  stroke-width: 0;
}

.deactivate-legend .highcharts-legend {
  display: none;
}

.ratios-title {
  display: flex;
  width: 100%;
  height: 40px;
  position: absolute;
  left: 10px;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  font-family: arial;
  color: #656464;
}

/* ------------- Reason Chart --------------*/
#c1ByC2 {
  display: flex;
}

.c1-by-c2-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 13px;
}

.c1-title-wrapper {
  position: relative;
  height: 51px;
  display: flex;
  margin-left: 14px;
  background-color: yellow;
}

.c1-by-c2-title {
  font-size: 12px;
}

.c1-container {
  display: flex;
  width: 157px;
  flex-direction: column;
  text-align: center;
  margin-top: -4px;
}

.c1-values {
  display: flex;
  justify-content: space-between;
  color: #e94888;
  font-size: 12px;
}

.deactivate-labels {
  opacity: 0;
}

/* Chromatography */
.chromatography-chart-container {
  position: absolute;
  bottom: 0;
  width: 320px;
  overflow: hidden;
  height: 406px;
  bottom: -2px;
}

.chromatography-title-container {
  display: flex;
  width: 310px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.chromatography-total-gas {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 80%;
  border-bottom: 1px solid #616161;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -4px;
  margin-top: -10px;
}

.chromatography-general-title {
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin-top: 3px;

  &>span {
    text-transform: none;
  }
}

.chromatography-title-box {
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.chromatography-column {
  width: 45%;
  display: inline-flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  font-size: 11px;
}

.chromatography-column>div {
  margin-top: 4px;
}

.c-tg {
  border-bottom: 1px dotted #000;
}

.c-c1 {
  color: #8a221b;
  border-bottom: 1px solid #8a221b;
}

.c-c2 {
  color: #468a1f;
  border-bottom: 1px solid #468a1f;
}

.c-c3 {
  color: #21236a;
  border-bottom: 1px solid #21236a;
}

.c-ic4 {
  color: #e65efd;
  border-bottom: 1px solid #e65efd;
  position: relative;
}

.c-nc4 {
  color: #a02f5f;
  ;
  border-bottom: 1px dashed #a02f5f;
  position: relative;
}

.c-ic5 {
  color: #8e52fd;
  ;
  border-bottom: 1px solid #8e52fd;
  ;
  position: relative;
}

.c-nc5 {
  color: #afacfd;
  border-bottom: 1px solid #afacfd;
  position: relative;
}

.arrow-line-container {
  position: relative;
  width: 151px;
  z-index: 20;
  font-size: 13px;
  height: 26px;
}

.arrow-line-arrow {
  width: 14px;
  height: 36px;
  position: absolute;
  right: 6px;
  top: 1px;
}

.arrow-line {
  position: absolute;
  left: -4px;
  width: 160px;
  height: 50px;
}

.arrow-line-wrapper {
  position: absolute;
  bottom: 48px;
  right: 10px;
}

.c2-c3-arrow-top {
  z-index: 50;
  left: 92px;
  top: 26px;
}

.c2-c3-arrow-bottom {
  transform: rotate(-90deg);
  bottom: 128px;
  right: 40px;
  z-index: 90;
}

.c2-iC4-arrow-top {
  transform: rotate(-50deg);
  top: -20px;
  left: 120px;
  z-index: 90;
}

// hidden the tooltip
.highcharts-tooltip {
  opacity: 0 !important;
}

.truncate {
  display: inline-block;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
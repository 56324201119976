.logo-geowellex{
    width: 4%;
    margin: 30px 0 0 30px;
}

.container-forgot{
  height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .form-forgot{
    width: 60%;
    height: 42%;
  }
  .btn__forgot {
    background-image: linear-gradient(to right, #058bb4, #0498c2, #03a6d0, #02b3de, #01c1ec);
    margin-top: 1rem;
    width: 214px;
    padding: 15px 15px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
  }
}


.container-header-forgot{
  width: 50%;
  height: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.title-forgot{
  color: white;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
}

.subtitle-forgot{
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.input-forgot{
  width: 460px;
}

hr{
  width: 120px;
  border: 1px dashed white;
  opacity: .5;
}

.error-text{
  color:white;
}

.container-sucess-menssage{
  width: 59%;
  height: 5vh;
  background-color: #23c138;
  display: flex;

  p{
    margin-top: 24px;
  }
}

.alert{
  font-size: 18px;
}

.msg-error-forgot {
  color: red;
  font-Size: 14px;
  text-Align: center;
}

.error-style {
  color: #ff4141; 
  font-Size: 14px; 
  text-Align: start;
  width: 60%;
}

.msg-reset-password {
  font-Size: 16px;
}

.msg-back-sing-in {
  font-Size: 15px;
  color: white;
  margin-Top: 5%;
}

.total-gas-header {
  $self: &;

  display: flex;
  z-index: 20;
  flex-direction: column;
  font-size: 12px;
  width: 160px;
  background-color: $white;
  position: relative;

  &__title {
    text-align: center;
    display: flex;
    width: 65px;
    top: 15px;
    left: 50px;
    justify-content: center;
    position: relative;
  }
  &__values {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-11;
  }

  &__checkbox {
    display: none;
    position: absolute;
    left: 115px;
    top: 10px;
  }

  &:hover {
    display: flex;
    > #{$self}__checkbox {
      display: flex;
    }
  }
}

.spin-dxc {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 900;
  background-color: rgba(0,0,0,0.5);
}

.spinner--dxc {
  border: 8px solid rgba(0, 0, 0, 0.6);
  border-left-color: #7959fc;
}

.message-dxc {
  margin-top: 10px;
  color: $gray-10;
  font-size: 20px;
}
$maxWidth: 94%;

.home {
  background-color: $bgDarkPage;
  overflow: hidden;
  position: relative;
  height: 100vh;

  &__modal {
    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-form-group {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 0.25rem;
      align-items: center;
      position: relative;

      >label {
        color: $gray-7;
        width: 104px;
        text-align: left;
      }

      .label-top-bottom {
        width: 90px;
      }

      .well-name-error {
        color: $red-6;
        font-size: 15px;
        position: absolute;
        bottom: -20px;
        left: 88px;
      }

      .well-name-input,
      .ant-input,
      .label-reference-depth,
      .group-reference-curve {
        background-color: $gray-3;
        border: 1px solid $gray-5;
        border-radius: 2px;
        color: $gray-8 !important;
      }

      .input-version {
        &:hover {
          border-color: $brand-color-6;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .well-name-input,
      .ant-select-selection {
        background: $gray-3 !important;
        border: 1px solid $gray-5;
        border-radius: 2px;
        color: $gray-8 !important;

        .ant-select-arrow {
          color: $gray-8;
        }

        &:hover {
          border-color: $brand-color-6;
        }

        &:focus {
          border-color: $brand-color-6;
          box-shadow: none;
        }
      }

      .ant-select-open .ant-select-selection {
        border-color: $brand-color-6;
        box-shadow: none;
      }

      .ant-slider-rail {
        background-color: $gray-5;
      }

      .ant-slider-track {
        background-color: $brand-color-5;
      }

      .ant-slider-handle.ant-tooltip-open {
        border-color: $brand-color-5;
        background-color: $gray-3;
      }

      .ant-slider:hover .ant-slider-rail {
        background-color: $gray-5;
      }
    }

    .chart-icon {
      width: 40px;
      height: 40px;
    }

    &-group {
      display: flex;
      margin-bottom: 0.6rem;
      margin-right: 70px;
    }

    &-footer {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: flex-end;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__content {
    display: flex;
    width: calc(100vw - 40px);
    margin-left: 70px;
    height: 100%;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    display: flex;
    width: $maxWidth;
    height: 76px;
    justify-content: space-between;

    &-select-label {
      margin-top: 2px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-name {
      display: inline-flex;
      height: 100%;
      align-items: center;
      font-size: 1.9rem;
      color: $white;
      font-family: 'Poppins';
    }

    @media(max-width: 1367px) {
      &-name {
        font-size: 1.5rem;
      }
    }

    @media(max-width: 1340px) {
      &-name {
        font-size: 1.5rem;
      }
    }

    @media(max-width: 1275px) {
      &-name {
        font-size: 1.3rem;
      }
    }

    &-select-well {
      &>.ant-select-selection {
        background-color: $bgDark3 !important;

        &:hover {
          border-color: $brand-color-6;
        }
      }

      & .ant-select-arrow .ant-select-arrow-icon>svg {
        fill: $white;
      }

      &-dropdown {
        background-color: $gray-3;
        box-shadow: 0px 2px 8px $black;

        .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
        .ant-select-dropdown-menu-item-selected {
          background-color: $gray-5;
        }
      }
    }

    &-actions {
      span {
        margin-right: 5px;
        height: 21px;
      }

      display: inline-flex;
      height: 100%;
      color: $white;
      align-items: center;
    }

    .btn-upload {
      font-size: 16px;
      border: 1px solid $gray-9;
      background: transparent;
      color: $gray-10;
      padding: 0.21rem 0.5rem;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 10px;
      outline: none;
      justify-content: center;
      align-items: center;
      display: flex;

      img {
        margin-right: 4px;
      }

      &:hover {
        border-color: $brand-color-6;
      }
    }
  }

  &__main {
    width: $maxWidth;
    display: flex;
    height: calc(100vh - 80px);
  }

  &__main-content {
    display: flex;
    width: 100%;
    height: calc(100% - 2vh);

    flex-direction: column;
    justify-content: flex-start;

    margin-top: 10px;

    overflow-y: scroll;
    color: #8e8d8d;
  }

  &__big-cards {
    width: 100%;
    min-height: 106px;
    display: inline-flex;
    justify-content: space-between;
  }

  &__cards {
    width: 100%;
    height: 74px;
    display: inline-flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &__charts {
    background: transparent;
    border-radius: 8px;
    background: $white;
    margin-top: 14px;
  }
}

.options-well {
  background-color: $gray-3;
  color: $gray-10;

  &:hover,
  &:active {
    background-color: $gray-5 !important;
  }
}

.menu-export-file {
  background-color: $gray-3;
}

.menu-item-export-file {
  background-color: $gray-3 !important;
  box-shadow: 0px 2px 8px $black;
  color: $gray-10;

  &:hover,
  &:focus {
    color: $gray-10;
  }
}

.pop-remove-well {
  .ant-popover-arrow {
    border-top-color: $gray-1 !important;
    border-left-color: $gray-1 !important;
  }

  .ant-popover-inner-content {
    background-color: $gray-1;
    box-shadow: 0px 4px 12px $black;
  }

  .ant-popover-message-title {
    font-weight: 700;
    color: $gray-9;
  }

  .ant-btn-sm {
    background-color: $gray-1;
    color: $gray-10;
    border-color: $gray-5;
    border-radius: 2px;

    &:hover {
      color: $brand-color-5;
      border-color: $brand-color-5;
    }
  }

  .ant-btn-primary {
    background-color: $brand-color-6;
    color: $gray-10;
    border-color: $brand-color-6;
    border-radius: 2px;

    &:hover {
      color: $gray-10;
      background-color: $brand-color-5;
      border-color: $brand-color-5;
    }
  }
}

.home {
  &__cards {
    @media (max-width: 1400px) {
      height: 60px;
      margin-top: 13px;
      margin-bottom: 11px;
    }
  }

  &__big-cards {
    @media (max-width: 1400px) {
      height: 99px;
    }
  }
}

.open-geopress-config,
.machine-learning-btn {
  background-color: $gray-4;
  border: 1px solid $gray-9;
  font-size: 16px;
  color: $gray-10;
  padding: 0.21rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    color: $brand-color-6 !important;
    border-color: $brand-color-6 !important;
    background-color: $gray-4 !important;
  }

  &:focus {
    background-color: $gray-4;
    border: 1px solid $gray-9;
    color: $gray-10;
  }
}

.btn-remove-well {
  display: flex;
  justify-content: center;
  background-color: transparent !important;
  border: none;
  width: 30px;
}


.btn-machine-learning {
  width: 190px;
  height: 32px;
  background-color: $gray-4;
  border: 1px solid $gray-9;
  font-size: 16px;
  color: $gray-10;
  padding: 0.21rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;

  &:hover,
  &:focus {
    color: $brand-color-6;
    border-color: $brand-color-6;
    background-color: $gray-4;
  }
}

.btn-upload-csv {
  width: 137px;
  height: 32px;
  background-color: $gray-4;
  border: 1px solid $gray-9;
  font-size: 16px;
  color: $gray-10;
  padding: 0.21rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;

  &:hover,
  &:focus {
    color: $brand-color-6;
    border-color: $brand-color-6;
    background-color: $gray-4;
  }
}

.ant-slider-tooltip .ant-tooltip-inner {
  background-color: $gray-4;
  border-radius: 2px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow::before {
  background-color: $gray-4;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: $gray-9;

  &::after {
    height: 2px;
    background-color: $gray-5;
  }
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: $gray-7;

  &::after {
    height: 2px;
    background-color: $brand-color-3;
  }
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: $gray-7;

  &::after {
    height: 2px;
    background-color: $gray-5;
  }
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: $brand-color-4;
  border-color: $brand-color-4;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: transparent;
  border: 1px solid $brand-color-5;

  >.ant-steps-icon {
    color: $brand-color-5;
  }
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent;
  border: 1px solid $gray-7;

  >.ant-steps-icon {
    color: $gray-7 !important;
  }
}

.ant-checkbox-wrapper {
  color: $white;
}

.match-curves-label {
  margin-right: 6px;
  width: 184px;
  display: flex;
  align-items: center;
}

.match-relative-label {
  color: $gray-7;
  margin-right: 16px;
  width: 100px;
  display: flex;
  text-align: end;
  justify-content: flex-end;
  align-items: center;
}

.select-dropdown-style {
  background-color: $gray-3;
  box-shadow: 0px 2px 8px $black;

  .ant-select-dropdown-menu-item {
    color: $gray-8;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-selected {
    background-color: $gray-5;
  }
}

.select-dropdown-density {
  background-color: $gray-3;
  box-shadow: 0px 2px 8px $black;

  .ant-select-dropdown-menu-item {
    color: $gray-8;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-selected {
    background-color: $gray-5;
  }
}

.depth,
.flow,
.rop,
.gamma_ray,
.total_gas,
.c1,
.c2,
.c3,
.c4,
.ic4,
.c5,
.ic5,
.isotope,
.c2h4,
.c2h6,
.c2s,
.wob,
.ecd,
.rpm,
.density,
.overburdenGradient,
.vertical_depth,
.mw {
  .ant-select-open .ant-select-selection {
    border-color: $brand-color-6;
    box-shadow: none;
  }
}

.cy-depth,
.cy-flow,
.cy-rop,
.cy-gamma_ray,
.cy-total_gas,
.cy-c1,
.cy-c2,
.cy-c3,
.cy-c4,
.cy-nc4,
.cy-nc5,
.cy-azimuth,
.cy-torque,
.cy-inclination,
.cy-ic4,
.cy-c5,
.cy-ic5,
.cy-isotope,
.cy-c2h4,
.cy-c2h6,
.cy-c2s,
.cy-wob,
.cy-ecd,
.cy-rpm,
.density,
.cy-overburdenGradient,
.vertical_depth,
.mw {
  .ant-select-selection {
    background-color: $gray-3;
    color: $gray-7;
    border-color: $gray-5;
    border-radius: 2px;

    &:hover,
    &:focus {
      border-color: $brand-color-6;
      box-shadow: none;
    }
  }

  .ant-select-arrow {
    color: $gray-6;
  }
}

.wrapper-inputs-match-curves {
  margin-bottom: 16px;
}

.wrapper-pressure {
  display: flex;
  width: 436px;

  label {
    display: flex;
    width: 125px;
    text-align: end;
    margin-right: 16px;
    color: $gray-7;
  }

  .pressure__input {
    width: 200px;
    background-color: $gray-3;
    color: $gray-7;
    border-color: $gray-5;
    border-radius: 2px;

    &:hover,
    &:focus {
      border-color: $brand-color-6;
      box-shadow: none;
    }
  }
}

.relative-curve {
  margin-top: 1rem;
}

.label-curve {
  text-align: left;
}

.group-reference-curve .ant-select {
  width: 100%;
}

.group-reference-curve .ant-select-selection {
  background-color: $white;
  color: rgba(0, 0, 0, 0.65);
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

.btn-cancel-conflict-data,
.cy-btn-cancel,
.cy-btn-previous {
  background-color: $gray-3;
  border: 1px solid $gray-5;
  border-radius: 2px;
  color: $gray-10;

  &:hover {
    background-color: $gray-3;
    border-color: $brand-color-5;
    color: $brand-color-5;
  }
}

.btn-next-conflict-data,
.cy-btn-next,
.cy-btn-apply,
.btn-add-bit-size {
  background-color: $brand-color-6;
  border: 1px solid $brand-color-6;
  border-radius: 2px;
  color: $gray-10;

  &:hover,
  &:focus {
    background-color: $brand-color-5;
    border-color: $brand-color-5;
    color: $gray-10;
  }
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  border-radius: 6px;
}

.cross-plot-toggle {
  display: flex;
}

// steps
.steps-content {
  margin-top: 16px;
  border-radius: 6px;
  min-height: 200px;
  justify-content: center;
  display: flex;
}

.steps-action {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}


.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: rgba(0, 0, 0, 0.8);
}

.ant-steps .ant-steps-horizontal .ant-steps-label-horizontal {
  width: 592px;
}

.ant-tooltip {
  z-index: 900 !important;
}

// Step - Confliting data
.wrapper-conflicting-data {
  display: flex;
  padding: 0px;
  border-bottom: 1px solid $gray-5;
}

.conflicting-data-title {
  position: relative;
  color: $colorGallery;
  font-size: 20px;
  line-height: 25px;
  padding-top: 10px;
  font-weight: 500;
  width: 100%;
}

.conflicting-data {
  &__description {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    color: $colorGallery;
    font-size: 16px;
    line-height: 16px;
  }

  &__las-name {
    color: $brand-color-5;
    margin-left: 4px;
    margin-right: 4px;
  }

  $inputColor: #8e8d8d;

  &__well-name {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    align-items: center;
    position: relative;
    justify-content: center;

    label {
      color: $white;
      text-align: left;
    }

    input {
      width: 80%;
      margin-left: 10px;
      border-radius: 2px;
      color: $gray-10;
      background-color: $gray-3;
      border-color: $gray-5;

      &:hover {
        border-color: $brand-color-6;
      }

      &:focus {
        border-color: $brand-color-6;
        box-shadow: none;
      }
    }

    &--disable {
      label {
        color: $gray-8;
      }

      input[disabled] {
        color: $gray-6;
        background-color: $gray-3;
        border-color: $gray-5;

        &:hover {
          border-color: $gray-5;
        }
      }
    }
  }
}

.form-radio {
  margin-top: 15px;

  &__option {
    position: relative;
    display: block;
    padding-right: 16px;
    color: $gray-8;
    font-size: 16px;
    line-height: 16px;
    margin-top: 25px;
  }
}

.ant-radio-inner {
  background-color: transparent;
  border: 1px solid $gray-5
}

// Responsive
.home {
  @media(max-width: 1400px) {
    &__header {
      height: 60px;
    }
  }
}

@media(max-width: 1420px) {
  .home {
    &-header {
      width: 97%;
    }

    &__content {
      margin-left: 50px;
      width: calc(100vw - 30px);
      background: $bgDarkPage;
    }
  }
}

.tabs-create-well {
  color: $gray-10;
  font-size: 16px;
  padding: 0px 10px;

  &--import {
    width: 550px;
    height: 100%;
  }

  &--preview {
    width: 1200px;
    height: 850px;
  }
}

.bit-size__form-group {
  margin-top: 40px !important;

  .ant-slider {
    width: 85%;
    margin: 0 auto;
  }
}

.bit-size__description {
  color: $gray-9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bit-size__title {
  font-size: 16px;
  text-align: center;
}

/* Clipboard Bit Size */
.clipboard__container {
  display: flex;
}

.clipboard__wrapper {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clipboard__input {
  padding: 5px;
  max-width: 70px;
  height: 31px;
  background-color: $bgDark2;
  border: none;
  outline: none;
}

.clipboard__btn {
  background: $bgDark2 !important;
  border: none;
  cursor: pointer;
}

.wrapper-overburden {
  margin-bottom: 10px;
}

.label-overburden {
  color: $brand-color-6;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.wrapper-options-overburden {
  width: 580px;
  background-color: $gray-3;
  border-radius: 5px;
  padding: 20px 20px 24px 20px;
}

.wrapper-select-density {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.wrapper-water-depth {
  display: flex;
  width: 386px;
  margin-top: 16px;

  label {
    width: 100px;
    color: $gray-7;
    margin-right: 16px;
    text-align: end;
  }

  .water-depth__input {
    width: 200px;
    background-color: $gray-3;
    color: $gray-7;
    border-color: $gray-5;
    border-radius: 2px;

    &:hover,
    &:focus {
      border-color: $brand-color-6;
      box-shadow: none;
    }
  }
}

.wrapper-default-curve {
  display: flex;
  margin-top: 19px;
}

.label-default-curve {
  color: $gray-7;
  margin-left: 33px;
  margin-right: 18px;
}

.switch-component {
  .ant-switch {
    background-color: $gray-5;
    width: 50px;

    &-checked {
      background-color: $brand-color-6;
    }
  }
}

.error-curves {
  color: red;
}

.wrapper-header-modal {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.ant-message-notice-content {
  background-color: $gray-3;
  color: $gray-9;
  box-shadow: 2px 2px 8px $black;
}

.general-main-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home__big-cards {
  background: $darkCard;
  align-self: center;
}

.home__header-select-well {
  width: 70px;
  margin-right: 0px;
}

.title-select-well {
  margin-top: 8px;
}

@media(max-width: 1367px) {
  .home__header-name {
    font-size: 1.2rem;
  }

  .wrapper-live-update {
    width: 36px;
    font-size: 12px;
    margin-top: -8px;
    margin-right: 3px;
  }

  .title-select-well {
    margin-top: 7px;
  }

}